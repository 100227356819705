import React from "react";
import { PropertyHeader, SectionCard, SectionDataBox } from "../../../components/StyledComponents/ParcelDetailStyles"
import { Collapse, Grid, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatMailingAddress, formatUnitNumberField, formatZip4Field } from "../../../utils/common/commonUtils";
import styles from '../parcelDetails.module.css';
import { SectionHeader, StyledKeyboardArrowDownIcon, StyledKeyboardArrowUpIcon } from "../../../components/StyledComponents/CommonControls";

function PropertyDetail(props: any) {
    const [propertyDetailsOpen, setPropertyDetails] = React.useState(true);
    let propertyDetails: any;
    let overviewData: any;
    if (props && props.propertyDetails) {
        propertyDetails = props.propertyDetails;
        overviewData = props.overview;
    }
    return (
        <div>
            {
                props &&
                propertyDetails &&
                <SectionCard className={styles.sectionPadding}>
                    <SectionHeader onClick={() => setPropertyDetails(!propertyDetailsOpen)} variant="h6">
                        {propertyDetailsOpen ? <StyledKeyboardArrowUpIcon data-testid="PropertyDetailClose" />
                            : <StyledKeyboardArrowDownIcon data-testid="PropertyDetailOpen" />} &nbsp; Property Detail
                    </SectionHeader>
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListDataTwoSection}>
                            <Typography className={styles.headerQuestion}>
                                Owner(s)
                            </Typography>
                            <Typography data-testid={"PropertyOwners"} className={styles.headerAnswer}>
                                {overviewData.owner_name}
                            </Typography>
                        </li>
                        <li className={styles.parcelListDataTwoSection}>
                            <Typography className={styles.headerQuestion}>
                                Legal Description Details
                            </Typography>
                            <Typography data-testid="PropertyLegalDescriptionDetails" className={styles.headerAnswer}>
                                {overviewData.brief_description}
                            </Typography>
                        </li>
                    </ul>
                    {/* <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Owner(s)</PropertyHeader></Grid>
                                <Grid item xs={3} data-testid="PropertyOwners"><Typography>{overviewData.owner_name}</Typography></Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}></Grid>

                            </Grid>
                        </SectionDataBox> */}
                    <Collapse in={propertyDetailsOpen} timeout="auto"
                        unmountOnExit>
                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Property Address
                                </Typography>
                                <Typography data-testid="PropertyFullAddress" className={styles.headerAnswer}>
                                    {propertyDetails.property_full_address} {formatUnitNumberField(overviewData.property_unit_type.trim(), overviewData.property_unit_number.trim())}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    City, State & ZIP
                                </Typography>
                                <Typography data-testid="PropertyCityState" className={styles.headerAnswer}>
                                    {formatMailingAddress("", propertyDetails.city, propertyDetails.state, formatZip4Field(propertyDetails.zip, overviewData.zip4), "")}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    County
                                </Typography>
                                <Typography data-testid="PropertyCounty" className={styles.headerAnswer}>
                                    {propertyDetails.county}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Property Use
                                </Typography>
                                <Typography data-testid="PropertyUse" className={styles.headerAnswer}>
                                    {propertyDetails.property_use}
                                </Typography>
                            </li>
                        </ul>
                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Subdivision
                                </Typography>
                                <Typography data-testid="PropertySubdivision" className={styles.headerAnswer}>
                                    {propertyDetails.lega_subdivision_name}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Parcel Number
                                </Typography>
                                <Typography data-testid="ParcelNumber" className={styles.headerAnswer}>
                                    {propertyDetails.apn}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Census Tract
                                </Typography>
                                <Typography data-testid="CensusTract" className={styles.headerAnswer}>
                                    {propertyDetails.property_address_census_tract}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Mailing Address(s)
                                </Typography>
                                <Typography data-testid="PropertyMailingAddress" className={styles.headerAnswer}>
                                    {propertyDetails.raw_mailing_full_street_address + " " + formatUnitNumberField(overviewData.current_owner_unit_type.trim(), overviewData.current_owner_unit_number.trim())}
                                    <br></br>{formatMailingAddress("", propertyDetails.current_owner_mailing_city, propertyDetails.current_owner_mailing_state, formatZip4Field(propertyDetails.current_owner_mailing_zip, propertyDetails.current_owner_mailing_zip4), "")}
                                </Typography>
                            </li>
                        </ul>
                        {/* <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Legal Description Details</PropertyHeader></Grid>
                                <Grid item xs={10} data-testid="PropertyLegalDescriptionDetails"><Typography>{overviewData.brief_description}</Typography> </Grid>
                            </Grid>
                        </SectionDataBox> */}
                        {/* <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Property Address</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyFullAddress">{propertyDetails.property_full_address} {formatUnitNumberField(overviewData.property_unit_type.trim(), overviewData.property_unit_number.trim())}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>City, State & ZIP</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="PropertyCityState">{formatMailingAddress("", propertyDetails.city, propertyDetails.state, formatZip4Field(propertyDetails.zip, overviewData.zip4), "")}</Typography></Grid>

                                <Grid item xs={1}><PropertyHeader>County</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyCounty">{propertyDetails.county}</Typography> </Grid>
                            </Grid>
                        </SectionDataBox> */}
                        {/* <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Property Use</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyUse">{propertyDetails.property_use}</Typography>  </Grid>
                                <Grid item xs={1}><PropertyHeader>Subdivision</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="PropertySubdivision">{propertyDetails.lega_subdivision_name}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Parcel Number</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="ParcelNumber">{propertyDetails.apn}</Typography> </Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Mailing Address(s)</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyMailingAddress">{formatMailingAddress(propertyDetails.raw_mailing_full_street_address, propertyDetails.current_owner_mailing_city, propertyDetails.current_owner_mailing_state, formatZip4Field(propertyDetails.current_owner_mailing_zip, propertyDetails.current_owner_mailing_zip4), formatUnitNumberField(overviewData.current_owner_unit_type.trim(), overviewData.current_owner_unit_number.trim()))}</Typography></Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1}><PropertyHeader>Census Tract</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="CensusTract">{propertyDetails.property_address_census_tract}</Typography></Grid>
                            </Grid>
                        </SectionDataBox> */}
                    </Collapse>
                </SectionCard >
            }
        </div>
    )
}

export default PropertyDetail;