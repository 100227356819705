import { Button, styled, Paper, Typography, Autocomplete, Tabs, Tab, PaginationItem, Pagination } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// export const GradientButton = styled(Button)`
//     background-image: linear-gradient(#0075aa, #03567b);
//     text-transform: none;
//     box-shadow: 0px 2px 3px 0px #00000040;
// `;

export const CustomDropdownIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
    color: theme.palette.primary.main, // Replace with any theme color
}));

export const StyledKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const SectionHeader = styled(Typography)(({ theme }) => `
    display: flex;
    align-items: center;
    padding: 25px 0px;
    font-weight: 700;
    color:${theme.palette.primary.main};
    font-size:20px;
    cursor: pointer;
`);

export const PolicyHeader = styled(Typography)(({ theme }) => `
    display: flex;
    align-items: center;
    padding: 10px 0px;
    font-weight: 700;
    color:${theme.palette.primary.main};
    cursor: pointer;
`);

export const StyledPagination = styled(Pagination)(({ theme }) => ({
    marginLeft: 'auto',
    "& .MuiPaginationItem-ellipsis": {
        color: theme.palette.primary.main,
    },
}));

export const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.MuiPaginationItem-root": {
        color: theme.palette.primary.main,
        fontSize: "14px",
        height: "32px",
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
        '&.Mui-disabled': {
            color: '#7A88B8', // Change to your desired color
        },
    },
}));

export const CustomStyledTab = styled(Tab)(({ theme }) => ({
    fontWeight: 700,
    backgroundColor: theme.palette.background.paper, // Use theme color or fallback
    color: `${theme.palette.primary.main} !important`, // Use theme color with !important
}));

export const StyledTabs = styled(Tabs)(({ theme }) => `
        .MuiTabs-scroller.MuiTabs-fixed  {
            border-radius: 5px 0px 0px 0px;
        }
        .MuiTabs-indicator {
            background-color: ${theme.palette.primary.main} !important;
        }
        .MuiTab-root.Mui-selected {
            color: ${theme.palette.primary.main} !important;
            background-color: white;
        }
`);

export const ProgressContainer = styled("div")(({ theme }) => `
  align-items: center;
  background: ${theme.palette.background.paper || "rgba(255, 255, 255, 1)"};
  border-radius: 16px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  position: relative;

  .MuiCircularProgress-root {
    color: ${theme.palette.primary.main};
    height: 60px !important;
    width: 60px !important;
  }
`);

export const StyledAutocomplete = styled(Autocomplete)`
  .Mui-disabled {
    background-color: ${(props) => props.theme.palette.background.paper};
    pointer-events: none;
    color:#BDBDBD;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    color:${(props) => props.theme.palette.primary.main};
  }
`;


export const GradientButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main};
  text-transform: none;
  box-shadow: 0px 2px 3px 0px #00000040;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const ClearButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};  // Text color from the theme
  border-color: ${(props) => props.theme.palette.primary.main};  // Border color from the theme
  text-transform: none;
  box-shadow: '0px 2px 3px 0px #00000040';
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};  // Darken the text color on hover
    border-color: ${(props) => props.theme.palette.primary.main};  // Darken the border color on hover
  }
`;

export const ThemedClearIcon = styled(ClearIcon)`
  color: ${(props) => props.theme.palette.primary.main}; // Use the icon color from the theme
  font-size: 25px; // Example size, adjust as needed
`;

// export const ClearButton = styled(Button)`
//     color:#0075AA;
//     border-color:#0075AA;
//     text-transform: none;
//     box-shadow: 0px 2px 3px 0px #00000040;
// `;

export const ValidationText = styled(Typography)`
    color:#7F7F7F;
    font-size: 14px;
    font-weight: 400;
`;

export const GridItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "none"
}));