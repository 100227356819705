import React from "react";
import { PropertyHeader, SectionCard, SectionDataBox } from "../../../components/StyledComponents/ParcelDetailStyles"
import { Collapse, Grid, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormatDateField } from "../../../utils/common/commonUtils";
import styles from '../parcelDetails.module.css';
import { SectionHeader } from "../../../components/StyledComponents/CommonControls";

function SaleDetail(props: any) {
    const [saleDetailsOpen, setSaleDetails] = React.useState(false);
    let saleDetails: any;
    if (props && props.saleDetails) {
        saleDetails = props.saleDetails;
    }
    return (
        <div>
            {
                props &&
                saleDetails &&
                <SectionCard className={styles.sectionPadding}>
                    <SectionHeader onClick={() => setSaleDetails(!saleDetailsOpen)} variant="h6">
                        {saleDetailsOpen ? <KeyboardArrowUpIcon data-testid="SaleDetailClose" />
                            : <KeyboardArrowDownIcon data-testid="SaleDetailOpen" />} &nbsp; Last Full Sale Details
                    </SectionHeader>
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Sale Price
                            </Typography>
                            <Typography data-testid="SalePrice" className={styles.headerAnswer}>
                                {saleDetails.price ? "$" : ""}{saleDetails.price ? Number(saleDetails.price).toLocaleString() : ""}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Sale Date
                            </Typography>
                            <Typography data-testid="SaleDate" className={styles.headerAnswer}>
                                {FormatDateField(saleDetails.sale_date)}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Recorder Doc #
                            </Typography>
                            <Typography data-testid="RecorderDoc" className={styles.headerAnswer}>
                                {saleDetails.document_number}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Book/Page
                            </Typography>
                            <Typography data-testid="BookPage" className={styles.headerAnswer}>
                                {saleDetails.book_number}{saleDetails.page_number ? "/" : ""}{saleDetails.page_number}
                            </Typography>
                        </li>
                    </ul>
                    {/* <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Sale Price</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="SalePrice"><Typography>{saleDetails.price ? "$" : ""}{saleDetails.price ? Number(saleDetails.price).toLocaleString() : ""}</Typography> </Grid>
                            <Grid item xs={1}><PropertyHeader>Sale Date</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="SaleDate"><Typography>{FormatDateField(saleDetails.sale_date)}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <Collapse in={saleDetailsOpen} timeout="auto"
                        unmountOnExit>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Recorder Doc #</PropertyHeader></Grid>
                                <Grid item xs={3} data-testid="RecorderDoc"><Typography>{saleDetails.document_number}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Book/Page</PropertyHeader></Grid>
                                <Grid item xs={2} data-testid="BookPage"><Typography>{saleDetails.book_number}{saleDetails.page_number ? "/" : ""}{saleDetails.page_number}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                    </Collapse> */}
                </SectionCard>
            }
        </div>
    )
}

export default SaleDetail;