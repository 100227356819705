import { axiosSecuredInstance } from "../../../configuration/axiosConfig";
import { stateParameter } from "../../../entities/ApiModel/CountyRequest";
import { CountyResponse } from "./getCountySlice";

export const fetchCountyList = async (param: stateParameter) => {
    if (!param || !param?.stateCode) {
        return;
    }
    const data: any = (await axiosSecuredInstance.get("api/v1/counties", { params: { state: param.stateCode } })).data.data;
    const response = { ...data, ...param };
    return response;
};