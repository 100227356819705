import React from "react";
import { PropertyHeader, SectionCard, SectionDataBox } from "../../../components/StyledComponents/ParcelDetailStyles"
import { Collapse, Grid, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { convertToPercent } from "../../../utils/common/commonUtils";
import styles from '../parcelDetails.module.css';
import { SectionHeader } from "../../../components/StyledComponents/CommonControls";

function AssessementDetail(props: any) {
    const [assesmentTaxOpen, setAssesment] = React.useState(false);
    let assessmentDetails: any;
    if (props && props.assessmentDetails) {
        assessmentDetails = props.assessmentDetails;
    }
    return (<div>
        {
            props &&
            props.assessmentDetails &&
            assessmentDetails &&
            <SectionCard>
                <SectionHeader onClick={() => setAssesment(!assesmentTaxOpen)} variant="h6">
                    {assesmentTaxOpen ? <KeyboardArrowUpIcon data-testid="AssessmentDetailClose" />
                        : <KeyboardArrowDownIcon data-testid="AssessmentDetailOpen" />} &nbsp; Assessment & Taxes
                </SectionHeader>
                <ul className={styles.parcelList}>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Assessment Year
                        </Typography>
                        <Typography data-testid="AssessmentYear" className={styles.headerAnswer}>
                            {assessmentDetails.assessment_year}
                        </Typography>
                    </li>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Total Assessed Value
                        </Typography>
                        <Typography data-testid="TotalAssessedValue" className={styles.headerAnswer}>
                            {assessmentDetails.assessment_total_value ? "$" + Number(assessmentDetails.assessment_total_value).toLocaleString() : ""}
                        </Typography>
                    </li>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Land Value
                        </Typography>
                        <Typography data-testid="LandValue" className={styles.headerAnswer}>
                            {assessmentDetails.assesment_land_value ? "$" + Number(assessmentDetails.assesment_land_value).toLocaleString() : ""}
                        </Typography>
                    </li>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Improvement Value
                        </Typography>
                        <Typography data-testid="ImprovementValue" className={styles.headerAnswer}>
                            {assessmentDetails.assessment_improvement_value ? "$" + Number(assessmentDetails.assessment_improvement_value).toLocaleString() : ""}
                        </Typography>
                    </li>
                </ul>
                <Collapse in={assesmentTaxOpen} timeout="auto"
                    unmountOnExit>
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Tax Year
                            </Typography>
                            <Typography data-testid="TaxYear" className={styles.headerAnswer}>
                                {assessmentDetails.tax_year}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Tax Amount
                            </Typography>
                            <Typography data-testid="TaxAmount" className={styles.headerAnswer}>
                                {assessmentDetails.tax_amount ? "$" + Number(assessmentDetails.tax_amount).toLocaleString() : ""}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Tax Status
                            </Typography>
                            <Typography data-testid="TaxStatus"
                                className={
                                    assessmentDetails.tax_status.toString().toLowerCase() === "delinquency found"
                                        ? `${styles.headerAnswer} ${styles.redText}`
                                        : `${styles.headerAnswer}`
                                }
                            // className={`${styles.headerAnswer} ${assessmentDetails.tax_status.toString().toLowerCase() === "delinquency found" ? styles.headerAnswer : ''}`}>
                            >{assessmentDetails.tax_status}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Delinquent Tax Year
                            </Typography>
                            <Typography data-testid="DelinquentTaxYear"
                                //  className={`${styles.headerAnswer} ${assessmentDetails.tax_status.toString().toLowerCase() === "delinquency found" ? styles.headerAnswer : ''}`}>
                                className={
                                    assessmentDetails.tax_status.toString().toLowerCase() === "delinquency found"
                                        ? `${styles.headerAnswer} ${styles.redText}`
                                        : `${styles.headerAnswer}`
                                }>
                                {assessmentDetails.tax_deliquent_year}
                            </Typography>
                        </li>
                    </ul>


                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Tax Account ID
                            </Typography>
                            <Typography data-testid="TaxAccountId" className={styles.headerAnswer}>
                                {assessmentDetails.tax_account_id}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Tax Exemption
                            </Typography>
                            <Typography data-testid="TaxExemption" className={styles.headerAnswer}>
                                {assessmentDetails.tax_exemtion}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Tax Rate Area
                            </Typography>
                            <Typography data-testid="TaxRateArea" className={styles.headerAnswer}>
                                {assessmentDetails.tax_rate_code_area}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Improvement Ratio
                            </Typography>
                            <Typography data-testid="ImprovementRatio" className={styles.headerAnswer}>
                                {convertToPercent(assessmentDetails.improvement_ratio) ? convertToPercent(assessmentDetails.improvement_ratio) + "%" : ""}
                            </Typography>
                        </li>
                    </ul>

                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Market Value Year
                            </Typography>
                            <Typography data-testid="MarketValueYear" className={styles.headerAnswer}>
                                {assessmentDetails.market_value_year}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Market Total
                            </Typography>
                            <Typography data-testid="MarketTotal" className={styles.headerAnswer}>
                                {assessmentDetails.market_total_value ? "$" + Number(assessmentDetails.market_total_value).toLocaleString() : ""}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Market Land Value
                            </Typography>
                            <Typography data-testid="MarketLandValue" className={styles.headerAnswer}>
                                {assessmentDetails.market_value_land ? "$" + Number(assessmentDetails.market_value_land).toLocaleString() : ""}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Market Improvement
                            </Typography>
                            <Typography data-testid="MarketImprovement" className={styles.headerAnswer}>
                                {assessmentDetails.market_improvement ? "$" + Number(assessmentDetails.market_improvement).toLocaleString() : ""}
                            </Typography>
                        </li>
                    </ul>
                </Collapse>
                {/* <SectionDataBox>
                    <Grid container>
                        <Grid item xs={2}><PropertyHeader>Assessment Year</PropertyHeader></Grid>
                        <Grid item xs={3} data-testid="AssessmentYear"><Typography>{assessmentDetails.assessment_year}</Typography> </Grid>
                        <Grid item xs={1}><PropertyHeader>Tax Year</PropertyHeader></Grid>
                        <Grid item xs={2} data-testid="TaxYear"><Typography>{assessmentDetails.tax_year}</Typography></Grid>
                        <Grid item xs={1}><PropertyHeader>Tax Exemption</PropertyHeader></Grid>
                        <Grid item xs={3} data-testid="TaxExemption"><Typography>{assessmentDetails.tax_exemtion}</Typography></Grid>
                    </Grid>
                </SectionDataBox> 
                <Collapse in={assesmentTaxOpen} timeout="auto"
                    unmountOnExit>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Total Assessed Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TotalAssessedValue"><Typography>{assessmentDetails.assessment_total_value ? "$" + Number(assessmentDetails.assessment_total_value).toLocaleString() : ""}</Typography> </Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Amount</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="TaxAmount"><Typography>{assessmentDetails.tax_amount ? "$" + Number(assessmentDetails.tax_amount).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Rate Area</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TaxRateArea"><Typography>{assessmentDetails.tax_rate_code_area}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Land Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="LandValue"><Typography>{assessmentDetails.assesment_land_value ? "$" + Number(assessmentDetails.assesment_land_value).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Account Id</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TaxAccountId"><Typography>{assessmentDetails.tax_account_id}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Improvement Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="ImprovementValue"><Typography>{assessmentDetails.assessment_improvement_value ? "$" + Number(assessmentDetails.assessment_improvement_value).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Status</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TaxStatus"><Typography>{assessmentDetails.tax_status}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Improvement Ratio</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="ImprovementRatio"><Typography>{convertToPercent(assessmentDetails.improvement_ratio) ? convertToPercent(assessmentDetails.improvement_ratio) + "%" : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Delinquent Tax Year</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="DelinquentTaxYear"><Typography>{assessmentDetails.tax_deliquent_year}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Market Value Year</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="MarketValueYear"><Typography>{assessmentDetails.market_value_year}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Market Land Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="MarketLandValue"><Typography>{assessmentDetails.market_value_land ? "$" + Number(assessmentDetails.market_value_land).toLocaleString() : ""}</Typography> </Grid>
                            <Grid item xs={1}><PropertyHeader>Market Improvement</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="MarketImprovement"><Typography>{assessmentDetails.market_improvement ? "$" + Number(assessmentDetails.market_improvement).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Market Total</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="MarketTotal"><Typography>{assessmentDetails.market_total_value ? "$" + Number(assessmentDetails.market_total_value).toLocaleString() : ""}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                </Collapse> */}
            </SectionCard>
        }
    </div>)
}

export default AssessementDetail;