import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchDocumentString } from '../Api/policyDocumentAPI';

export interface DocumentState {
    documentList: {};
    status: 'idle' | 'loading' | 'failed';
}

export interface DocumentParam {
    fullPolicyNumber: string;
    docName: string;
    rowNo: string;
    fileNumber: string;
}

const initialState: DocumentState = {
    documentList: {},
    status: 'idle',
};

export const resetDocumentState = createAction('RESET_DOCUMENT_SLICE');

export const getDocument = createAsyncThunk(
    'policyDocument/get',
    async (documentParam: DocumentParam) => {
        const response = await fetchDocumentString(documentParam.fileNumber, documentParam.fullPolicyNumber, documentParam.rowNo);
        return response;
    }
);

export const policyDocumentSlice = createSlice({
    name: 'policyDocument',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocument.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDocument.fulfilled, (state, action) => {
                state.status = 'idle';
                const obj: any = {
                    isRequestCompleted: true,
                    documentData: action.payload.documentString.data,
                    matchCode: action.payload.documentString.match_code,
                    policyNumber: action.payload.policyNumber,
                    documentId: action.payload.documentId
                };
                state.documentList = obj;
            })
            .addCase(getDocument.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(resetDocumentState, () => initialState);
    },
});

export const policyDoc = (state: RootState) => state.rootReducer.policyDocument;
export default policyDocumentSlice.reducer;