import { Box, FormControl, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import { OWNER_SEARCH_TAB, POLICY_OWNER_SEARCH_TAB } from '../../utils/constants/tabConstants';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getSessionStorage, setSessionStorage } from '../../utils/common/commonUtils';
import {
    EXCEPTION_MATCH_TEXT, keyEntriesPerPage,
    keyPageNo, MULTIPLE_MATCH_TEXT, NO_COVERAGE_TEXT, NO_MATCH_FOUND, OwnerNameSearchErrorMessages, SINGLE_MATCH_TEXT
} from '../../utils/constants/constants';
import MatchCodes from '../../utils/enums/MatchCodes';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import styles from './policyOwnerNameSearch.module.css';
import { PolicyOwnerSearchData, resetPolicyOwnerSearchState, searchByPolicyOwnerSearch, setPolicyOwnerSearchToState } from './policyOwnerNameSearchSlice';
import { OwnerNameSearchRequest } from '../../entities/ApiModel/OwnerNameSearchRequest';
import { OwnerNameSearchForm } from '../../entities/Types/OwnerNameSearchForm';
import PolicySearchResult from '../policySearch/searchResult/PolicySearchResult';
import PolicyOwnerNameSearchForm from './PolicyOwnerNameSearchForm';

export const errorMessages = {
    InvalidOwnerNameError: "OwnerName_Is_Invalid",
    MaxCharactersError: "Maximum_50_Characters",
    OwnerNameRequiredError: "OwnerName_Is_Required"
}

function PolicyOwnerNameSearch() {

    const [errorMessage, setErrorMessage] = useState("");
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");

    const [isAssesementOwnerSearch, setAssesementOwnerSearch] = React.useState(true);
    const [isPolicyOwnerSearch, setPolicyOwnerSearch] = React.useState(false);
    const policyOwnerSearchState = useAppSelector(PolicyOwnerSearchData);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    async function handleSubmit(formData: OwnerNameSearchForm) {
        if (!formData)
            return;
        dispatch(resetPolicyOwnerSearchState());

        const defaultPageNo = 1;
        const defaultRowsPerPage = 10;
        setSessionStorage(keyPageNo + POLICY_OWNER_SEARCH_TAB, defaultPageNo);
        let page = defaultPageNo;//parseInt(getSessionStorage(keyPageNo + LEGAL_SEARCH_TAB) ?? defaultPageNo.toString());
        let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + POLICY_OWNER_SEARCH_TAB) ?? defaultRowsPerPage.toString())
        let request: OwnerNameSearchRequest = {
            state: formData.stateField.id ?? "",
            fips: formData.countyField.fips ?? "",
            county: formData.countyField.county ?? "",
            ownerName: formData.ownerName ?? "",
            offset_value: ((page - 1) * rowsPerPage).toString(),
            fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
        }

        formData.pageNo = page;
        setMatchCode("");
        setLoading(true);

        dispatch(searchByPolicyOwnerSearch(request));
        dispatch(setPolicyOwnerSearchToState(JSON.parse(JSON.stringify(formData))));
    }

    const handleChangeRowsPerPage = (count: number) => {
        dispatch(resetPolicyOwnerSearchState());
    }

    const handleChangePage = (newPage: number) => {
        // setPageNo(newPage);
        // dispatch(resetPolicyOwnerSearchState());
    }

    useEffect(() => {
        if (policyOwnerSearchState
            && policyOwnerSearchState.property
            && policyOwnerSearchState.property.match_code !== "" && !policyOwnerSearchState.property.isExecuting) {
            setLoading(false)
            setMatchCode(policyOwnerSearchState.property.match_code);
        }

        if (policyOwnerSearchState.status === "failed") {
            setLoading(false);
        }
    }, [policyOwnerSearchState]);

    useEffect(() => {
        if (matchCode && matchCode !== "") {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setMatchText(EXCEPTION_MATCH_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                }
            }
        } else {
            setMatchText("");
        }
    }, [matchCode]);

    async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let request: OwnerNameSearchRequest = {
            state: policyOwnerSearchState.formFields?.stateField.id ?? "",
            fips: policyOwnerSearchState?.formFields?.countyField.fips ?? "",
            county: policyOwnerSearchState?.formFields?.countyField.county ?? "",
            ownerName: policyOwnerSearchState?.formFields?.ownerName ?? "",
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
        }
        setMatchCode("");
        setMatchText("");
        let formData = { ...policyOwnerSearchState?.formFields, "pageNo": page }
        dispatch(searchByPolicyOwnerSearch(request));
        dispatch(setPolicyOwnerSearchToState(formData));
    }

    const handleAssesementOwnerSearchClick = () => {
        setPolicyOwnerSearch(false)
        setAssesementOwnerSearch(true)
    }

    const handlePolicyOwnerSearchClick = () => {
        setAssesementOwnerSearch(false)
        setPolicyOwnerSearch(true)
    }

    const handleFormError = (errors: any) => {
        if (errors.stateField?.id?.type === "required" &&
            errors.countyField?.fips?.type === "required" && errors.ownerName?.type === "required") {
            setErrorMessage(OwnerNameSearchErrorMessages.All_Fields_Required);
        }
        else if (errors.countyField?.fips?.type === "required" && errors.ownerName?.type === "required") {
            setErrorMessage(OwnerNameSearchErrorMessages.County_OwnerName_Required);
        }
        // else if (errors.countyField?.fips?.type === "required") {
        //     setErrorMessage(OwnerNameSearchErrorMessages.County_Required);
        // }
        else if (errors.ownerName?.type === "required") {
            setErrorMessage(OwnerNameSearchErrorMessages.OwnerName_Required);
        }
        // else if (errors.ownerName?.message === errorMessages.InvalidOwnerNameError) {
        //     setErrorMessage(OwnerNameSearchErrorMessages.Invalid_OwnerName);
        // }
        // else if (errors.APNField?.message === errorMessages.DoubleAstrikError) {
        //     setErrorMessage(OwnerNameSearchErrorMessages.Double_Astrik_Error);
        // }
        else if (errors.ownerName?.type === "max") {
            setErrorMessage(OwnerNameSearchErrorMessages.Maximum_APN_Error);
        }
        else {
            setErrorMessage("");
        }
    }

    async function handleClearSearch() {
        setMatchCode("");
    }

    let columns = [
        { id: 'apn', label: 'APN', minWidth: 180 },
        { id: 'ownerName', label: 'OWNER NAME', minWidth: 290 },
        {
            id: 'address',
            label: 'ADDRESS',
            minWidth: 195,
        },
        {
            id: 'city',
            label: 'CITY',
            minWidth: 160,
        },
        {
            id: 'state',
            label: 'STATE',
            minWidth: 60,
        },
        {
            id: 'formattedZip',
            label: 'ZIP',
            minWidth: 135,
            format: (value: number) => value.toFixed(2),
        },
        {
            id: 'landUse',
            label: 'LAND USE',
            minWidth: 200,
        },
        {
            id: 'assessmentLegal',
            label: 'ASSESSMENT LEGAL',
            minWidth: 190,
        }
    ];

    return (
        <Box>
            <>
                {isLoading &&
                    <LoadingProgressModel />
                }

                <Box className={styles.boxLayout}>
                    <PolicyOwnerNameSearchForm
                        raiseError={handleFormError}
                        onSubmit={handleSubmit}
                        onClear={handleClearSearch}
                        formFields={policyOwnerSearchState?.formFields}
                    />
                    {
                        errorMessage
                        &&
                        <ErrorMessage errorMessageText={errorMessage} />
                    }
                </Box>
                {
                    policyOwnerSearchState
                    && policyOwnerSearchState.property
                    && Object.keys(policyOwnerSearchState.property.PolicyOwnerSearch).length > 0
                    &&
                    <MatchStatus matchCode={matchCode} matchText={matchText} />
                }
                {
                    policyOwnerSearchState
                    && policyOwnerSearchState.property
                    && Object.keys(policyOwnerSearchState.property.PolicyOwnerSearch).length > 0
                    &&
                    <PolicySearchResult
                        totalRowCount={policyOwnerSearchState?.property?.total_count ?? 0}
                        tabName={OWNER_SEARCH_TAB}
                        subTabName={POLICY_OWNER_SEARCH_TAB}
                        loggingEventName={POLICY_OWNER_SEARCH_TAB}
                        fetchNextData={handleFetchNext}
                        propertyList={policyOwnerSearchState.property.PolicyOwnerSearch}
                        changeRowsPerPage={handleChangeRowsPerPage} />
                }
            </>
        </Box>
    )
}

export default PolicyOwnerNameSearch