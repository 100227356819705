import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { fetchCountyList } from './getCountyAPI';
import { stateParameter } from '../../../entities/ApiModel/CountyRequest';

export interface CountyList {
    fips: string,
    county: string,
}

export interface CountyResponse {
    match_code: string,
    message: string,
    isExecuting: boolean,
    countyList: Record<string, any[]>;
    // county_list: CountyList;
}

export interface CountyState {
    county: CountyResponse;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}

export const resetCountyState = createAction('REVERT_COUNTY_STATE');

const initialState: CountyState = {
    county: {
        match_code: "",
        message: "",
        countyList: {},
        isExecuting: false,
    },
    status: 'idle',
};

export const getCountyByStateCode = createAsyncThunk(
    'APN/getCounty',
    async (param: stateParameter) => {
        const response = await fetchCountyList(param);
        return response;
    }
);

export const getCountySlice = createSlice({
    name: 'County',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCountyByStateCode.pending, (state) => {
                state.status = 'loading';
                // state.county.county_list = { }
                state.county.isExecuting = true;
                // state.county.message = "";
            })
            .addCase(getCountyByStateCode.fulfilled, (state, action) => {
                state.status = 'idle';
                if (!action.payload)
                    return;
                state.county.countyList[action.payload.pageName] = action.payload.county_list;
                state.county.match_code = action.payload.match_code;
                state.county.message = action.payload.message;
                state.county.isExecuting = false;
            })
            .addCase(getCountyByStateCode.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(resetCountyState, () => initialState);
    },
});

export const CountyListData = (state: RootState) => state.rootReducer.CountyList;
export default getCountySlice.reducer;
