import { Box, Button, FormControl, Radio, RadioGroup, Typography } from "@mui/material"
import { TabPanel } from "@mui/lab"
import styles from './legalSearch.module.css';
import { ValidationText } from "../../components/StyledComponents/CommonControls";
import LegalSearchForm from "./legalSearchForm/LegalSearchForm";
import React, { useEffect, useState } from "react";
import { LegalSearchFormType } from "../../entities/Types/LegalSearchForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LegalSearchData, resetLegalSearchState, searchByLegalSearch, setLegalSearchToState } from "./legalSearchForm/LegalSearchSlice";
import { LegalSearchRequest } from "../../entities/ApiModel/LegalSearchRequest";
import MatchCodes from "../../utils/enums/MatchCodes";
import {
    EXCEPTION_MATCH_TEXT, LegalSearchFormErrorMessages, MULTIPLE_MATCH_TEXT, NO_COVERAGE_TEXT, NO_MATCH_FOUND,
    SINGLE_MATCH_TEXT,
    keyEntriesPerPage,
    keyPageNo
} from "../../utils/constants/constants";
import LoadingProgressModel from "../../components/ui/circularProgress/LoadingProgress";
import MatchStatus from "../../components/ui/matchStatus/MatchStatus";
import PolicySearchResult from "../policySearch/searchResult/PolicySearchResult";
import { ASSESSMENT_SEARCH_TAB, LEGAL_SEARCH_TAB } from "../../utils/constants/tabConstants";
import ErrorMessage from "../../components/ui/errorMessage/ErrorMessage";
import { getSessionStorage, setSessionStorage, specialCharacterFormatting } from '../../utils/common/commonUtils';
import { tabIdentifier } from "../../components/ui/propertyTable/tabIdentifierSlice";
import AssessmentSearch from "../assessmentSearch/AssessmentSearch";
import { StyledRadioButton } from "../../components/StyledComponents/StyledRadioButton";

const LegalSearch = () => {


    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const dispatch = useAppDispatch();
    const legalSearchState = useAppSelector(LegalSearchData);

    async function handleSubmit(formData: LegalSearchFormType) {
        try {
            if (!formData)
                return;
            dispatch(resetLegalSearchState());

            const defaultPageNo = 1;
            const defaultRowsPerPage = 10;
            setSessionStorage(keyPageNo + LEGAL_SEARCH_TAB, defaultPageNo);
            let page = defaultPageNo;
            let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + LEGAL_SEARCH_TAB) ?? defaultRowsPerPage.toString())
            let request: LegalSearchRequest = {
                state: formData.state.id ?? "",
                fips: formData.fips.fips ?? "",
                county: formData?.fips.county ?? "",
                subdivision_lot_from: specialCharacterFormatting(formData.subdivision_lot_from ?? ""),
                subdivision_lot_to: specialCharacterFormatting(formData.subdivision_lot_to ?? ""),
                block: formData.block ?? "",
                subdivision: formData.subdivision ?? "",
                phase: formData.phase ?? "",
                condominium_building: formData.condominium_building ?? "",
                condominium_unit_from: specialCharacterFormatting(formData.condominium_unit_from ?? ""),
                condominium_unit_to: specialCharacterFormatting(formData.condominium_unit_to ?? ""),
                condominium: formData.condominium ?? "",
                volume: formData.volume ?? "",
                book: formData.book ?? "",
                page: formData.page ?? "",
                section: formData.section ?? "",
                township: formData.township ?? "",
                range: formData.range ?? "",
                abstract_name: formData.abstract_name ?? "",
                abstract_number: formData.abstract_number ?? "",
                quarter1: formData.quarter1 ?? "",
                quarter2: formData.quarter2 ?? "",
                quarter3: formData.quarter3 ?? "",
                offset_value: ((page - 1) * rowsPerPage).toString(),
                fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
            }
            formData.pageNo = page;
            setMatchCode("");
            setLoading(true);

            dispatch(searchByLegalSearch(request));
            dispatch(setLegalSearchToState(JSON.parse(JSON.stringify(formData))));
        }
        catch (e) {

        }
    }

    useEffect(() => {
        if (matchCode && matchCode !== "") {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setMatchText(EXCEPTION_MATCH_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                }
            }
        } else {
            setMatchText("");
        }
    }, [matchCode]);

    useEffect(() => {
        if (legalSearchState
            && legalSearchState.property
            && legalSearchState.property.match_code !== "" && !legalSearchState.property.isExecuting) {
            setLoading(false)
            setMatchCode(legalSearchState.property.match_code);
        }

        if (legalSearchState.status === "failed") {
            setLoading(false);
        }
    }, [legalSearchState]);


    async function handleClearSearch() {
        setMatchCode("");
    }

    const raiseError = (errors: any) => {
        if (errors.state?.id?.message === "Required" &&
            errors.fips?.fips?.message === "Required") {
            setErrorMessage(LegalSearchFormErrorMessages.NoFieldError);
        }
        else if (errors.fips?.fips?.message === "Required") {
            setErrorMessage(LegalSearchFormErrorMessages.CountyRequiredError);
        }
        else if (errors.fieldValidationError?.type === "Empty_Form_Value") {
            setErrorMessage(LegalSearchFormErrorMessages.NoLegalFields);
        }
        else if (errors.subdivision_lot_from?.type === "Greater_Value_Error" || errors.subdivision_lot_to?.type === "Greater_Value_Error") {
            setErrorMessage(LegalSearchFormErrorMessages.LotFromGreaterValue);
        }
        else if (Object.keys(errors).length > 0) {
            const keys = Object.keys(errors);
            let message = keys?.length > 0 ? errors[keys[0]]?.message : "";
            setErrorMessage(message);
        }
        else {
            setErrorMessage("")
        }
    }

    async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let request: LegalSearchRequest = {
            state: legalSearchState?.formFields?.state.id ?? "",
            fips: legalSearchState?.formFields?.fips.fips ?? "",
            county: legalSearchState?.formFields?.fips.county ?? "",
            subdivision_lot_from: specialCharacterFormatting(legalSearchState?.formFields?.subdivision_lot_from ?? ""),
            subdivision_lot_to: specialCharacterFormatting(legalSearchState?.formFields?.subdivision_lot_to ?? ""),
            block: legalSearchState?.formFields?.block ?? "",
            subdivision: legalSearchState?.formFields?.subdivision ?? "",
            phase: legalSearchState?.formFields?.phase ?? "",
            condominium_building: legalSearchState?.formFields?.condominium_building ?? "",
            condominium_unit_from: specialCharacterFormatting(legalSearchState?.formFields?.condominium_unit_from ?? ""),
            condominium_unit_to: specialCharacterFormatting(legalSearchState?.formFields?.condominium_unit_to ?? ""),
            condominium: legalSearchState?.formFields?.condominium ?? "",
            volume: legalSearchState?.formFields?.volume ?? "",
            book: legalSearchState?.formFields?.book ?? "",
            page: legalSearchState?.formFields?.page ?? "",
            section: legalSearchState?.formFields?.section ?? "",
            township: legalSearchState?.formFields?.township ?? "",
            range: legalSearchState?.formFields?.range ?? "",
            abstract_name: legalSearchState?.formFields?.abstract_name ?? "",
            abstract_number: legalSearchState?.formFields?.abstract_number ?? "",
            quarter1: legalSearchState?.formFields?.quarter1 ?? "",
            quarter2: legalSearchState?.formFields?.quarter2 ?? "",
            quarter3: legalSearchState?.formFields?.quarter3 ?? "",
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
        }
        setMatchCode("");
        setMatchText("");
        let formData = { ...legalSearchState?.formFields, "pageNo": page }
        dispatch(searchByLegalSearch(request));
        dispatch(setLegalSearchToState(formData));
    }

    const handleChangeRowsPerPage = () => {
        dispatch(resetLegalSearchState());
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box className={styles.boxLayout}>
                <LegalSearchForm raiseError={raiseError} onSubmit={handleSubmit} onClear={handleClearSearch} formFields={legalSearchState?.formFields} />
                {
                    errorMessage
                    &&
                    <ErrorMessage errorMessageText={errorMessage} />
                }
            </Box>

            {
                legalSearchState
                && legalSearchState.property
                && Object.keys(legalSearchState.property.LegalSearch).length > 0
                &&
                <MatchStatus matchCode={matchCode} matchText={matchText} />
            }
            {
                legalSearchState
                && legalSearchState.property
                && Object.keys(legalSearchState.property.LegalSearch).length > 0
                &&
                <PolicySearchResult
                    totalRowCount={legalSearchState?.property?.total_count ?? 0}
                    tabName={LEGAL_SEARCH_TAB}
                    loggingEventName={LEGAL_SEARCH_TAB}
                    fetchNextData={handleFetchNext}
                    propertyList={legalSearchState.property.LegalSearch}
                    changeRowsPerPage={handleChangeRowsPerPage} />
            }
        </>
    )
}

export default LegalSearch