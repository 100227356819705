import { DownloadImageButton, PropertyHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import { Box, Collapse, Grid, Typography } from '@mui/material'
import styles from './policySearchResult.module.css';
import styled from '@emotion/styled';
import { formatMailingAddress, formatZip4Field, getLoggingEventName } from '../../../utils/common/commonUtils';
import { useNavigate } from 'react-router-dom';
import { setTabToState } from '../../../components/ui/propertyTable/tabIdentifierSlice';
import { useAppDispatch } from '../../../app/hooks';
import logAPI from '../../../common/Api/logAPI';
import { LoggingEvent, ParcelDetail_Log_Event } from '../../../utils/constants/constants';
import APNList from './APNList';
import APNListLastLine from './APNListLastLine';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import { StyledKeyboardArrowDownIcon, StyledKeyboardArrowUpIcon } from '../../../components/StyledComponents/CommonControls';

interface Props {
    apnDetail: any,
    policyInfo: any,
    isAPNDetailsOpen: boolean,
    key?: number,
    tabName: string,
    index: number,
    subTabName?: string,
    isStarter?: Boolean,
    isDirect?: Boolean,
    isReference?: Boolean,
    loggingEventName: string,
    handleAPNDetailsClick: Function,

}

const SectionDataBox = styled(Box)`
        padding: 15px;
        box-shadow: none;
`;

const APNResult = ({ apnDetail, policyInfo, isAPNDetailsOpen, tabName, index, isStarter, isDirect, isReference, loggingEventName, handleAPNDetailsClick, subTabName = "" }: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let policyNumber = policyInfo?.policy_no;

    // const [isAPNDetailsOpen, setAPNDetailsOpen] = useState(false);
    // useEffect(() => {
    //     if (isPropertyDetailsOpen) {
    //         setAPNDetailsOpen(true);
    //     } else {
    //         setAPNDetailsOpen(false);
    //     }
    // }, [isPropertyDetailsOpen]);
    const handleAPNDetailsOpen = (row_number: number) => {
        // setPropertyDetails(!propertyDetailsOpen)
        handleAPNDetailsClick(row_number + "" + index);
    }

    const handleParcelDetailClick = (propertyId: any, fips: any) => {
        if (!propertyId && !fips)
            return;

        logAPI({
            Event: ParcelDetail_Log_Event, //getLoggingEventName(tabName) + LoggingEvent.ParcelView, //PolicySearch_Loggin_Event.Policy_Search_Parcel_Event,
            FileNumber: policyInfo?.file_number,
            PolicyNumber: policyInfo?.policy_no,
            FullStreetAddress: apnDetail?.Address,
            City: apnDetail?.city,
            State: apnDetail?.state,
            UnitNumber: apnDetail?.unit,
            Zip: apnDetail?.zip,
            Zip4: apnDetail?.zip4,
            APN: apnDetail?.assessor_apn,
            Fips: apnDetail?.fips,
            SearchOptionUsed: getLoggingEventName(loggingEventName)
        });
        dispatch(setTabToState({ tabName: tabName, subTabName: subTabName }));
        let query = "/parcel-detail/" + propertyId + "?fips=" + fips;
        navigate(query);
    }

    return (
        <>
            <Box className={styles.apnListAlignment}>
                <Grid item sx={{ cursor: 'pointer' }} className={styles.arrowAlignment}
                    onClick={() => handleAPNDetailsOpen(policyInfo?.row_number)}>
                    {/* onClick={() => setAPNDetailsOpen(!isAPNDetailsOpen)}> */}
                    <Typography className={styles.arrowPosition}>
                        {isAPNDetailsOpen ? <StyledKeyboardArrowUpIcon data-testid={policyNumber + "PropertyDetailClose"} />
                            : <StyledKeyboardArrowDownIcon data-testid={policyNumber + "PropertyDetailOpen"} />}
                    </Typography>
                </Grid>
                <ul className={`${styles.parcelList} ${styles.anotherClass}`}>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Policy APN
                        </Typography>
                        <Typography data-testid={policyNumber + "_APN"} className={styles.headerAnswer}>
                            {apnDetail?.policy_apn}
                        </Typography>
                    </li>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Assessor APN
                        </Typography>
                        <Typography data-testid={policyNumber + "_AssessorAPN"} className={styles.headerAnswer}>
                            {!apnDetail?.spectr_propert_id || !apnDetail?.fips ? "N/A" : apnDetail?.assessor_apn}
                        </Typography>
                    </li>
                    <li className={styles.parcelListData}
                    // style={{
                    //     flex: !isStarter && !isReference ? '0 0 50%' : '0 0 25%',
                    // }}
                    >
                        <Typography className={styles.headerQuestion}>
                            Address
                        </Typography>
                        <Typography data-testid={policyNumber + "_Address"} className={styles.headerAnswer}>
                            {formatMailingAddress(apnDetail?.Address.trim(), apnDetail?.city.trim(), apnDetail?.state.trim(), formatZip4Field(apnDetail?.zip, apnDetail?.zip4), "")}
                        </Typography>
                    </li>
                    {isStarter && isReference &&
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Proximity
                            </Typography>
                            <Typography data-testid={policyNumber + "_Proximity"} className={styles.headerAnswer}>
                                {apnDetail?.search_proximity && !isNaN(parseFloat(apnDetail?.search_proximity)) ? parseFloat(apnDetail?.search_proximity).toFixed(2) + ' Miles' : ''}
                            </Typography>
                        </li>
                    }
                    {
                        apnDetail?.spectr_propert_id &&
                        apnDetail?.fips &&
                        !isStarter &&
                        <li className={styles.parcelListData}>
                            <Grid item xs={1.9} md={2.7} lg={3.2} sx={{ display: 'flex', justifyContent: 'end' }}>
                                <DownloadImageButton data-testid={policyNumber + "_ParcelDetail"} onClick={() => handleParcelDetailClick(apnDetail?.spectr_propert_id, apnDetail?.fips)}
                                    sx={{ maxHeight: "36px", backgroundColor: "white" }} variant="outlined" startIcon={<FormatAlignLeftIcon />}>
                                    Parcel Detail
                                </DownloadImageButton>
                            </Grid>
                        </li>
                    }
                </ul>
                {/* {isStarter ?
                    <Collapse in={isPropertyDetailsOpen} timeout="auto" unmountOnExit>
                        <APNList policyNumber={policyInfo?.policy_no} isReference={isReference ?? false} searchProximity={policyInfo?.search_proximity} apnDetail={apnDetail} isStarter={isStarter ?? false} handleParcelDetailClick={handleParcelDetailClick} />
                    </Collapse>
                    :
                    <APNList policyNumber={policyInfo?.policy_no} isReference={isReference ?? false} searchProximity={policyInfo?.search_proximity} apnDetail={apnDetail} isStarter={isStarter ?? false} handleParcelDetailClick={handleParcelDetailClick} />
                } */}
                <Collapse in={isAPNDetailsOpen} timeout="auto" unmountOnExit>
                    {/* <SectionDataBox> */}
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Policy Subdivision
                            </Typography>
                            <Typography data-testid={policyNumber + "_PolicySubdivision"} className={styles.headerAnswer}>
                                {apnDetail?.policy_division}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Assessment Subdivision
                            </Typography>
                            <Typography data-testid={policyNumber + "_AssessmentSubdivision"} className={styles.headerAnswer}>
                                {apnDetail?.spectr_propert_id && apnDetail?.fips ? (apnDetail?.assessment_division ? apnDetail?.assessment_division : "N/A") : ""}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Lot
                            </Typography>
                            <Typography data-testid={policyNumber + "_Lot"} className={styles.headerAnswer}>
                                {apnDetail?.lot}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Block
                            </Typography>
                            <Typography data-testid={policyNumber + "_Block"} className={styles.headerAnswer}>
                                {apnDetail?.block}
                            </Typography>
                        </li>
                    </ul>
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Book/Volume
                            </Typography>
                            <Typography data-testid={policyNumber + "_BookVolume"} className={styles.headerAnswer}>
                                {apnDetail?.book_volume}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Page
                            </Typography>
                            <Typography data-testid={policyNumber + "_Page"} className={styles.headerAnswer}>
                                {apnDetail?.page}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Section
                            </Typography>
                            <Typography data-testid={policyNumber + "_Section"} className={styles.headerAnswer}>
                                {apnDetail?.section}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Township
                            </Typography>
                            <Typography data-testid={policyNumber + "_Township"} className={styles.headerAnswer}>
                                {apnDetail?.township}
                            </Typography>
                        </li>
                    </ul>
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Range
                            </Typography>
                            <Typography data-testid={policyNumber + "_Range"} className={styles.headerAnswer}>
                                {apnDetail?.range}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Quarter 1
                            </Typography>
                            <Typography data-testid={policyNumber + "_Quarter1"} className={styles.headerAnswer}>
                                {apnDetail?.quarter1}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Quarter 2
                            </Typography>
                            <Typography data-testid={policyNumber + "_Quarter2"} className={styles.headerAnswer}>
                                {apnDetail?.quarter2}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Quarter 3
                            </Typography>
                            <Typography data-testid={policyNumber + "_Quarter3"} className={styles.headerAnswer}>
                                {apnDetail?.quarter3}
                            </Typography>
                        </li>
                    </ul>
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListDataOneSection}>
                            <Typography className={styles.headerQuestion}>
                                {isDirect ? "Direct Starter" : isReference ? "Reference Starter" : ""} Full Legal Description
                                {/* Full Legal Description */}
                            </Typography>
                            <Typography data-testid={policyNumber + "_FullLegalDescription"} className={styles.headerAnswer}>
                                {apnDetail?.full_legal_description}
                            </Typography>
                        </li>
                    </ul>
                    {/* <Grid container rowSpacing={3.5}>
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1} md={1.6} lg={1.7}><PropertyHeader>Policy Subdivision</PropertyHeader></Grid>
                        <Grid item xs={2} md={4.2} lg={3.3}><Typography data-testid={policyNumber + "_PolicySubdivision"}>{apnDetail?.policy_division}</Typography> </Grid>
                        <Grid item xs={1} md={1.6} lg={2.3}>{apnDetail?.spectr_propert_id && apnDetail?.fips && <PropertyHeader>Assessment Subdivision</PropertyHeader>}</Grid>
                        <Grid item xs={2} md={4.2} lg={4.4}><Typography data-testid={policyNumber + "_AssessmentSubdivision"}>{apnDetail?.spectr_propert_id && apnDetail?.fips ? (apnDetail?.assessment_division ? apnDetail?.assessment_division : "N/A") : ""}</Typography></Grid>
                        
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={0.5} md={1.3} lg={1}><PropertyHeader>Lot</PropertyHeader></Grid>
                        <Grid item xs={1.3} md={2} lg={1.5}><Typography data-testid={policyNumber + "_Lot"}>{apnDetail?.lot}</Typography> </Grid>
                        <Grid item xs={0.5} md={1.3} lg={1.1}><PropertyHeader>Block</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.6} lg={1.5}><Typography data-testid={policyNumber + "_Block"}>{apnDetail?.block}</Typography> </Grid>
                        <Grid item xs={1} md={1.6} lg={1.3}><PropertyHeader>Book/Volume</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.8} lg={1.5}><Typography data-testid={policyNumber + "_BookVolume"} sx={{ wordBreak: 'break-word' }}>{apnDetail?.book_volume}</Typography> </Grid>
                        <Grid item xs={0.5} md={1.2} lg={1.5}><PropertyHeader>Page</PropertyHeader></Grid>
                        <Grid item xs={1} md={0.8} lg={2.3}><Typography data-testid={policyNumber + "_Page"}>{apnDetail?.page}</Typography> </Grid>
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1} md={1.5} lg={1.3}><PropertyHeader>Section</PropertyHeader></Grid>
                        <Grid item xs={2} md={3.9} lg={2.7}><Typography data-testid={policyNumber + "_Section"}>{apnDetail?.section}</Typography> </Grid>
                        <Grid item xs={1} md={1.3} lg={1.3}><PropertyHeader>Township</PropertyHeader></Grid>
                        <Grid item xs={0.8} md={2} lg={0.5}><Typography data-testid={policyNumber + "_Township"}>{apnDetail?.township}</Typography> </Grid>
                        <Grid item xs={0.5} md={1.5} lg={1.2}><PropertyHeader>Range</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.4} lg={4.7}><Typography data-testid={policyNumber + "_Range"}>{apnDetail?.range}</Typography> </Grid>
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1} md={1.5} lg={1.3}><PropertyHeader>Quarter 1</PropertyHeader></Grid>
                        <Grid item xs={2} md={3.9} lg={2.7}><Typography data-testid={policyNumber + "_Quarter1"}>{apnDetail?.quarter1}</Typography> </Grid>
                        <Grid item xs={1} md={1.5} lg={1.3}><PropertyHeader>Quarter 2</PropertyHeader></Grid>
                        <Grid item xs={0.8} md={1.8} lg={0.5}><Typography data-testid={policyNumber + "_Quarter2"}>{apnDetail?.quarter2}</Typography> </Grid>
                        <Grid item xs={1} md={1.5} lg={1.2}><PropertyHeader>Quarter 3</PropertyHeader></Grid>
                        <Grid item xs={1} md={1.4} lg={4.7}><Typography data-testid={policyNumber + "_Quarter3"}>{apnDetail?.quarter3}</Typography> </Grid>
                        {!isStarter &&
                            <Grid item xs={0.3} md={0.4} lg={0.3}>

                            </Grid>
                        }
                        <Grid item xs={1.5} md={3} lg={2.5}><PropertyHeader>
                            {isDirect ? "Direct Starter" : isReference ? "Reference Starter" : ""} Full Legal Description:
                        </PropertyHeader></Grid>
                        <Grid item xs={10.2} md={8.6} lg={9.2} data-testid={policyNumber + "_FullLegalDescription"}><Typography>{apnDetail?.full_legal_description}</Typography> </Grid>
                    </Grid>*/}
                    {/* </SectionDataBox> */}
                </Collapse>
            </Box>
            {/* {isStarter ?
                <Collapse in={isPropertyDetailsOpen} timeout="auto" unmountOnExit>
                    <APNListLastLine />
                </Collapse>
                :
                <APNListLastLine />
            } */}

            {/* <SectionDataBox className={styles.topPadding}>
                <Grid container>
                    <Grid item xs={0.3}>
                    </Grid>
                    <Grid item xs={11.7} className={styles.lastLineBorder}><PropertyHeader></PropertyHeader></Grid>
                </Grid>
            </SectionDataBox> */}
        </>
    )
}

export default APNResult