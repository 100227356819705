import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
  minWidth?: string;
}

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.main, // Default circle color when not checked
  '&.Mui-checked': {
    color: theme.palette.primary.main, // Circle color when checked
  },
}));

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} control={<StyledRadio />} />
))(({ theme, minWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  '.MuiFormControlLabel-label': {
    minWidth: minWidth || '200px',
  },
  variants: [
    {
      props: { checked: true },
      style: {
        '.MuiFormControlLabel-label': {
          color: "#262525",
          fontWeight: 'bold',
        },
      },
    },
    {
      props: { checked: false },
      style: {
        '.MuiFormControlLabel-label': {
          fontWeight: 'normal',
        },
      },
    },
  ],
}));


interface StyledRadioButtonProps extends FormControlLabelProps {
  minWidth?: string;
}

export function StyledRadioButton(props: StyledRadioButtonProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} minWidth={props.minWidth} {...props} />;
}