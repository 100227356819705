// Tab Value Field
export const ADDRESS_TAB: string = "addressTab"
export const POLICY_ADDRESS_TAB: string = "policyAddressTab"
export const APN_TAB: string = "apnTab"
export const POLICY_APN_TAB: string = "policyAPNTab"
export const POLICY_SEARCH_TAB: string = "policySearchTab"
export const LEGAL_SEARCH_TAB: string = "legalSearchTab"
export const ASSESSMENT_SEARCH_TAB: string = "assessmentSearchTab"
export const OWNER_SEARCH_TAB: string = "ownerSearchTab"
export const POLICY_OWNER_SEARCH_TAB: string = "policyOwnerSearchTab"
export const GRANTOR_TAB: string = "grantorTab"
export const LEGAL_TAB: string = "legalTab"
export const ASSESSMENT_TAB: string = "assessmentTab"
export const LEGAL_SEARCH_FORM: string = "legalSearchForm"
export const ASSESSMENT_SEARCH_FORM: string = "AssesementSearchForm"
// Tab Header Text
export const ADDRESS_TAB_HEADER: string = "Address Search"
export const APN_TAB_HEADER: string = "APN Search"
export const POLICY_SEARCH_TAB_HEADER: string = "Policy Search"
export const LEGAL_SEARCH_TAB_HEADER: string = "Legal Search"
export const OWNER_SEARCH_TAB_HEADER: string = "Owner Name Search"
export const GRANTOR_TAB_HEADER: string = "Grantor/Grantee Search"
export const LEGAL_TAB_HEADER: string = "Legal Search"