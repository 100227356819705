import React from 'react'
import Popup from '../popup/Popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

interface Props {
    isOpen: boolean
    notificationText?: string
    onClose?: React.MouseEventHandler<HTMLElement>;
}

function Warning({ isOpen, notificationText = "The document image is not currently available.", onClose }: Props) {
    const WarningFont = () => {
        return (
            <FontAwesomeIcon fontSize={'40px'} color='white' icon={faExclamationCircle} />
        )
    }

    const headerStyle = {
        backgroundImage: 'linear-gradient(180deg, #FFC400 0%, #FFAB00 100%)'
    }

    return (
        <Popup notificationText={notificationText} onClose={onClose} headerGradientStyle={headerStyle} fontComponent={<WarningFont />} isOpen={isOpen} ></Popup>
    )
}


export default Warning