// src/theme.js

import { createTheme, Theme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#001251',
            dark: "#212121",
            light: "#405CBF"
        },
        secondary: {
            main: '#004D82',
        },
        background: {
            default: '#E5E5E5', 
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#001251',
                },
            },
        },
        // MuiAutocomplete: {
        //     styleOverrides: {
        //         root: {
        //             '& .Mui-disabled': {
        //                 backgroundColor: '#f5f5f5', // Use the theme color here if preferred
        //                 pointerEvents: 'none',
        //                 color: '#BDBDBD',
        //             },
        //             '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
        //                 color: '#558FAA', // Or use `theme.palette.primary.main`
        //             },
        //         },
        //     },
        // },
    },
});

export default theme;
