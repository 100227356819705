import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Navbar from '../../layout/navbar/navbar';
import { GradientButton } from '../../components/StyledComponents/CommonControls';
import styles from './login.module.css';
import { loginRequest } from '../../configuration/MsalAuthService';
import Footer from '../../layout/footer/Footer';
import { useMsal } from '@azure/msal-react';
import { MailToBody, MailToCustomerCare, MailToSubject, keyEntriesPerPage } from '../../utils/constants/constants';
import LoginImageSection from './BackgroundImage';
import Exception from '../../components/ui/notification/exception/Exception';
import { useState } from 'react';
import { removeKeySessionStorageItemsStartingWith } from '../../utils/common/commonUtils';

export type GraphData = {
    displayName: string,
    jobTitle: string,
    mail: string,
    businessPhones: string[],
    officeLocation: string
};

export default function Login() {

    const { instance } = useMsal();
    const [exception, setException] = useState(false);

    const triggerMailTo = (event: any) => {
        event.preventDefault();
        const mailTo = "mailto:" + MailToCustomerCare + "?subject=" + MailToSubject + "&body=" + MailToBody;
        window.location.href = mailTo;
    }

    const triggerContactUs = (event: any) => {
        event.preventDefault();
        const mailTo = "mailto:" + MailToCustomerCare;
        window.location.href = mailTo;
    }

    const onPopClose = () => {
        setException(false);
    }

    const handleLoginClick = () => {
        if (instance) {
            removeKeySessionStorageItemsStartingWith(keyEntriesPerPage);
            instance.loginRedirect(loginRequest).catch(
                (error) => {
                    console.log(error);
                    setException(true);
                }
            );
        }
    }

    return (
        <>
            <Exception isOpen={exception} onClose={onPopClose} />
            <Navbar isLogin={true} />
            <Grid container component="main" sx={{ minHeight: '80vh' }}>
                <Grid item xs={12} sm={8} md={6} color={"background.default"}>
                    <Box padding={'0px 10%'} sx={{
                        my: 15,
                        mx: 4,
                    }}>
                        <Box className={styles.alignDiv}>
                            <Box className={styles.alignItems}>
                                <Typography fontSize={'48px'} color={"primary.dark"} fontFamily={'Montserrat'} fontWeight={700}>Welcome to SPECTR</Typography>
                                <Typography fontSize={'24px'} color={"primary.dark"} fontWeight={500}>Stewart Title's New Centralized Data Repository</Typography>
                            </Box>
                            <Box className={styles.alignItems} gap={'24px'}>
                                <Typography fontSize={'16px'} color={"primary.dark"} fontWeight={400} textAlign={'center'}>We are thrilled to introduce SPECTR, the cutting-edge platform that serves as the hub for Stewart Title's extensive data assets. With SPECTR, we bring together over 25 million Owner and Lender Title Policies and an unparalleled wealth of information on over 140+ million parcels in the United States, covering over 99.9% of the US population.</Typography>
                                <Typography fontSize={'16px'} color={"primary.dark"} fontWeight={400} textAlign={'center'}>Join us on SPECTR and unlock the power of our centralized data repository. Discover valuable insights, streamline your research, and make data-driven decisions with confidence. Stewart Title's commitment to excellence is exemplified through SPECTR, the ultimate tool for accessing and utilizing our extensive data assets.</Typography>
                            </Box>
                            <Box className={styles.alignItems}>
                                <GradientButton sx={{ color: 'white', height: "42px", width: '95px' }} size='large' onClick={handleLoginClick}>Enter</GradientButton>
                                <Box sx={{ marginTop: "30px", marginRight: "40px" }}>
                                    <Link color={"primary.main"} className={styles.requestButton} onClick={triggerMailTo}>Request Access</Link>
                                    <Link color={"primary.main"} className={styles.requestButton} onClick={triggerContactUs}>Contact Us</Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={styles.alignItems}>
                        <Typography fontSize={'14px'} color={'#7F7F7F'} fontWeight={400}>For the best experience, it is highly recommended that you use the Chrome internet browser.</Typography>
                    </Box>
                </Grid>
                <LoginImageSection />
            </Grid>
            <Grid item md={12}>
                <Box className={styles.alignFooterContent}>
                    <Footer />
                    {/* <FooterLinks /> */}
                </Box>
            </Grid>
        </>
    );
}