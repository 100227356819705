import {
    Box, Container, Grid, Typography, Link
} from "@mui/material";
import Navbar from "../../layout/navbar/navbar";
import styles from './parcelDetails.module.css';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PropertyDetail from "./propertyDetail/PropertyDetail";
import SaleDetail from "./saleDetail/SaleDetail";
import PhysicalCharacteristic from "./physicalCharacteristics/PhysicalCharacteristics";
import AssessementDetail from "./assessementDetail/AssessementDetail";
import PropertyOverview from "./propertyOverview/PropertyOverview";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getParcelDetails, parcelDetailsData, resetParcelDetail } from "./parcelDetailsSlice";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from "../../layout/footer/Footer";
import { ParcelDetailsRequest } from "../../entities/ApiModel";
import NoContent from "../noContent/NoContent";
import LoadingProgressModel from "../../components/ui/circularProgress/LoadingProgress";
import PolicySearchResult from "../policySearch/searchResult/PolicySearchResult";
import EmptySection from "./EmptyStarterSection/EmptySection";

function ParcelDetails() {
    const [noContent, setNoContent] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const parcelDetailsState = useAppSelector(parcelDetailsData);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { propertyId } = useParams();
    const [searchParams] = useSearchParams();
    const fips = searchParams.get('fips');
    const parcelDetailsRequest: ParcelDetailsRequest = {
        propertyId: propertyId ?? "",
        fips: fips ?? "",
    };

    useEffect(() => {
        dispatch(resetParcelDetail());
        dispatch(getParcelDetails(parcelDetailsRequest));
        scrollToTop();
    }, []);

    useEffect(() => {
        if (parcelDetailsState.status === "loading") {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [parcelDetailsState]);

    const {
        parcelDetails: {
            propertyDetails,
            overviewDetails: { overview_details },
            salesDetails: { sales_details },
            physicalDetails: { physical_char_details },
            assessmentDetails: { assessment_details },
            directStarter,
            referenceStarter,
            mapProperties
        }
    } = parcelDetailsState

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const returnToSearch = () => {
        navigate("/");
    };
    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Container maxWidth={false}>
                <Navbar />
                {
                    parcelDetailsState
                    && parcelDetailsState.parcelDetails
                    && propertyDetails.property_details &&
                    <>
                        <Box minHeight={'80vh'} sx={{ marginTop: '25px' }}>
                            {/* <Typography className={styles.addressSearchText} variant="h5" component="h5">
                                <DescriptionIcon className={styles.searchIcon} /> &nbsp; Parcel Detail
                            </Typography> */}
                            <Box className={styles.boxLayout} sx={{ padding: '25px 25px 25px 25px' }}>
                                <PropertyOverview
                                    returnToSearch={returnToSearch}
                                    overview={overview_details}
                                    lotNo={physical_char_details.legal_lot_number}
                                    tractNo={physical_char_details.legal_tract_number}
                                    mapProperties={mapProperties} />
                                <PropertyDetail propertyDetails={propertyDetails.property_details} overview={overview_details} />
                                <SaleDetail saleDetails={sales_details} />
                                <PhysicalCharacteristic physicalDetails={physical_char_details} />
                                <AssessementDetail assessmentDetails={assessment_details} />



                                {/* {directStarter?.direct_starter_details?.map((item: any, index: any) => {
                                    return <DirectStarter directStarterProps={item} key={index} />
                                })}
                                {
                                    (directStarter?.direct_starter_details.length <= 0)
                                    &&
                                    <DirectStarter directStarterProps={{}} />
                                }
                                {referenceStarter?.reference_starter_details?.map((item: any, index: any) => {
                                    return <ReferenceStarter referenceStarter={item} key={index}
                                        isSubDivision={referenceStarter?.search_status === search_status_subdivision ? true : false} />
                                })}
                                {
                                    (referenceStarter?.reference_starter_details.length <= 0)
                                    &&
                                    <ReferenceStarter referenceStarter={{}} />
                                } */}


                            </Box>
                        </Box>
                        {directStarter &&
                            ((Object.keys(directStarter?.direct_starter_details)?.length ?? 0) > 0) ?
                            <PolicySearchResult
                                isPaginationLogicRequired={false}
                                isPaginationRequired={false}
                                isDirectStarter={true}
                                propertyList={directStarter?.direct_starter_details}
                                tabName="DirectStarter_ParcelDetail"
                                loggingEventName="DirectStarter_ParcelDetail"
                            />
                            :
                            <EmptySection isDirect={true} />
                        }
                        {referenceStarter &&
                            ((Object.keys(referenceStarter?.reference_starter_details)?.length ?? 0) > 0) ?
                            <PolicySearchResult
                                isPaginationLogicRequired={false}
                                isPaginationRequired={false}
                                isReferenceStarter={true}
                                propertyList={referenceStarter?.reference_starter_details}
                                tabName="ReferenceStarter_ParcelDetail"
                                loggingEventName="ReferenceStarter_ParcelDetail"
                            />
                            :
                            <EmptySection isReference={true} />
                        }
                        <Box sx={{ padding: '20px', paddingBottom: '0px', display: 'flex', justifyContent: 'center' }}>
                            <Grid>
                                <ArrowUpwardIcon color="primary" sx={{ fontSize: "20px" }} />&nbsp;
                            </Grid>
                            <Grid>
                                <Link onClick={scrollToTop} sx={{textDecoration: "none"}} color="primary" ><Typography sx={{  fontWeight: 700, fontSize: '15px', cursor: 'pointer' }}>Back To Top</Typography></Link>
                            </Grid>
                        </Box>
                        <Footer />
                    </>
                }
                {
                    noContent
                    &&
                    <NoContent />
                }
            </Container >

        </>
    )
}

export default ParcelDetails;