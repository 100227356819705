import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { GradientButton } from '../StyledComponents/CommonControls';
import { getCookie, setCookie } from '../../utils/common/commonUtils';
import SpectrUpdates from './SpectrUpdates';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root.MuiDialog-paper': {
        borderRadius: "10px"
    },
}));

interface Props {
    isOpen: Boolean
}

function SplashScreen({ isOpen = false }: Props) {
    const [open, setOpen] = React.useState(false);
    let cookieName = "SplashScreenCount";
    let maximumCount = 3;
    const [checked, setChecked] = React.useState(false);

    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');
    React.useEffect(() => {
        let count = Number(getCookie(cookieName));
        if (count < 3 && isOpen) {
            setOpen(true);
            setCookie(cookieName, (count + 1).toString(), 60);
        }
    }, [isOpen]);

    const handleClickOpen = () => {

    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked); // Get the checked value
    };

    const handleClose = (event: object, reason: string) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
        }
        setOpen(false);
        if (checked) {
            setCookie(cookieName, maximumCount.toString(), 60);
        }
    };

    const handleContinueClick = () => {
        setOpen(false);
        handleClose({}, "ContinueCick");
    };


    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{ borderRadius: '10px' }}
            disableEscapeKeyDown
            maxWidth={maxWidth}>
            <DialogContent dividers>
                <Box sx={{ minHeight: "400px" }}>
                    <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                        <Typography sx={{ m: 0, p: 2, fontSize: '20px', fontWeight: '600' }} id="customized-dialog-title">
                            Attention SPECTR Updates
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleContinueClick}
                            sx={(theme) => ({
                                height: "30px",
                                width: "30px",
                                backgroundColor: "#F5F5F5"
                            })}>
                            <CloseIcon sx={{ color: "#001251", fontSize: "20px" }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ padding: '16px' }}>
                        <SpectrUpdates />
                        {/* <Typography gutterBottom sx={{ fontSize: '14px' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography> */}
                    </Box>
                </Box>
                <Box sx={{ padding: '16px', display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked
                            checked={checked}
                            onChange={handleChange} sx={{
                                color: "#001251",
                                '&.Mui-checked': {
                                    color: "#001251",
                                },
                            }} />} label="Do not show this message again" />
                    </FormGroup>
                    <GradientButton onClick={handleContinueClick} type="submit" size="large" variant="contained" data-testid="SearchButton">
                        <Typography>Continue</Typography>
                    </GradientButton>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    )
}

export default SplashScreen