import React from 'react';
import { Box, Typography, Link, List, ListItem, ListItemIcon } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';

const SpectrUpdates = () => {
  return (
    <Box sx={{ lineHeight: 1.6, color: "black" }}>
      <Typography paragraph>
        We’ve updated the SPECTR application where users are now able to search for policies using the following new features:
      </Typography>
      <List sx={{ paddingLeft: 2 }}>
        {['Address', 'APN', 'Policy Number or File Number', 'Legal Description', 'Owner Name'].map((item) => (
          <ListItem key={item} sx={{ paddingLeft: 0 }}>
            <ListItemIcon sx={{ minWidth: 20 }}>
              <SquareIcon sx={{ fontSize: "0.6rem", color: "black" }} color="primary" />
            </ListItemIcon>
            {item}
          </ListItem>
        ))}
      </List>
      {/* <List sx={{ paddingLeft: 2, color: "black" }}>
        <ListItem sx={{ display: 'list-item', pl: 2 }}>Address</ListItem>
        <ListItem sx={{ display: 'list-item', pl: 2 }}>APN</ListItem>
        <ListItem sx={{ display: 'list-item', pl: 2 }}>Policy Number or File Number</ListItem>
        <ListItem sx={{ display: 'list-item', pl: 2 }}>Legal Description</ListItem>
        <ListItem sx={{ display: 'list-item', pl: 2 }}>Owner Name</ListItem>
      </List> */}
      <Typography paragraph>
        In addition, users are now able to search the county assessment data using the following new features:
      </Typography>
      <List sx={{ paddingLeft: 2 }}>
        {['Legal Description', 'Owner Name'].map((item) => (
          <ListItem key={item} sx={{ paddingLeft: 0 }}>
            <ListItemIcon sx={{ minWidth: 20 }}>
              <SquareIcon sx={{ fontSize: '0.6rem', color: 'black' }} /> {/* Smaller icon */}
            </ListItemIcon>
            {item}
          </ListItem>
        ))}
      </List>
      {/* <List sx={{ paddingLeft: 2, color: "black" }}>
        <ListItem sx={{ display: 'list-item', pl: 2 }}>Legal Description</ListItem>
        <ListItem sx={{ display: 'list-item', pl: 2 }}>Owner Name</ListItem>
      </List> */}
      <Typography paragraph>As well as the previous features, Address and APN.</Typography><br></br>
      <Typography paragraph>
        Here’s a{' '}
        <Link
          href="https://vimeo.com/908359389/4c69cca5da?ts=0&share=copy"
          target="_blank"
          rel="noopener noreferrer"
        >
          video
        </Link>{' '}
        that will walk you through the new features.
      </Typography>
    </Box>
  );
};

export default SpectrUpdates;
