import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { Box, List, ListItem } from '@mui/material';
import StewartTableFooterPaginator from './StewartTableFooterPaginator';
import { formatUnitNumberField, formatZip4Field, getLoggingEventName, getSessionStorage, setSessionStorage } from '../../../utils/common/commonUtils';
import { keyEntriesPerPage, keyPageNo, ParcelDetail_Log_Event } from '../../../utils/constants/constants';
import logAPI from '../../../common/Api/logAPI';
import { setTabToState } from '../propertyTable/tabIdentifierSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import styles from './SpectrTablePagination.module.css';

interface Props {
    tabName: string,
    columns: any[],
    rows: any[],
    totalRowCount: number
    handleChangePage1: Function,
    handleChangeRowsPerPage1: Function,
    propertyList: any,
    fetchNextData: Function,
    navigationTabName: string
}

export default function SpectrTablePagination({ navigationTabName, tabName, columns, rows, totalRowCount, handleChangePage1,
    handleChangeRowsPerPage1, propertyList, fetchNextData }: Props) {
    //const { columns, rows, tabName } = props
    const isFirstRender = React.useRef(true);
    const defaultRowsPerPage = 10;
    const defaultPageNo = 1;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [page, setPage] = React.useState<number>(parseInt(getSessionStorage(keyPageNo + tabName) ?? defaultPageNo.toString()));

    const [rowsPerPage, setRowsPerPage] = React.useState<number>(parseInt(getSessionStorage(keyEntriesPerPage + tabName) ?? defaultRowsPerPage.toString()));

    const handleChangePage = (newPage: number) => {
        handleChangePage1(newPage);
        setSessionStorage(keyPageNo + tabName, newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (count: any) => {
        handleChangeRowsPerPage1(count);

        setRowsPerPage(count);
        sessionStorage.removeItem(keyPageNo + tabName);
        setSessionStorage(keyEntriesPerPage + tabName, count);
        setPage(1);
        if (handleChangeRowsPerPage1) {
            handleChangeRowsPerPage1();
            if (fetchNextData) {
                fetchNextData(defaultPageNo, (defaultPageNo - 1) * count, (defaultPageNo - 1) * count + count);
            }
        }
        //setRowsPerPage(count);
        //sessionStorage.removeItem(keyPageNo + tabName);
        //setSessionStorage(keyEntriesPerPage + tabName, count);
    };

    function onRowClicked(row: any) {
        if (!row)
            return;
        let query = "/parcel-detail/" + row.propertyId + "?fips=" + row.fips;
        navigate(query);
        dispatch(setTabToState({ tabName: navigationTabName, subTabName: tabName }));
        logAPI({
            Event: ParcelDetail_Log_Event,
            FullStreetAddress: row.address,
            city: row.city,
            state: row.state,
            zip: row.zip,
            zip4: row.zip4,
            UnitNumber: row.unit,
            APN: row.apn,
            Fips: row.fips,
            SearchOptionUsed: getLoggingEventName(tabName)
        });
    }

    React.useEffect(() => {
        if (!isFirstRender.current && !propertyList[page]) {
            console.log("page", page);
            console.log("Rows Per Page", (page - 1) * rowsPerPage)
            console.log("fetchNext", (page - 1) * rowsPerPage + rowsPerPage)
            if (fetchNextData)
                fetchNextData(page, (page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        }
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
        //console.log("Page Re-rendered");
        // if (fetchNextData)
        //     fetchNextData((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        // let data = propertyList
        //     .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        // if (data.length === 0) {
        //     if (fetchNextData)
        //         // fetchNextData((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        //     // alert("No Data");
        //     return;
        // }
    }, [page]);

    type AddressProps = {
        address: string;
        unitNumber: string;
        unitType: string;
    };

    function AddressWithUnit({ address, unitNumber, unitType }: AddressProps) {
        return (
            <List sx={{ padding: 0 }}>
                <ListItem sx={{ padding: 0 }}>{address} {formatUnitNumberField(unitType, unitNumber)}</ListItem>
            </List>
        );
    }

    const StyledTable = styled(Table)(({ theme }) => `
    tr:first-child td:last-child {
        border-top-right-radius: 4px;
    }
    tr:first-child td:first-child {
        border-top-left-radius: 4px;
    }
    tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
    }
    tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
    }
`);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        borderTop: '1px solid #BDBDBD',
        '&:first-child': {
            borderLeft: '1px solid #BDBDBD',
        },
        '&:last-child': {
            borderRight: '1px solid #BDBDBD',
        },
        '&': {
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.15000000596046448px',
            textAlign: 'left',
            padding: '8px 8px 8px 12px',
        },
    }));

    const StyledTableBody = styled(TableBody)(({ theme }) => ({
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    }));

    const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
        '&': {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.17000000178813934px',
            textAlign: 'left',
            color: "#212121",
            backgroundColor:'#ffff !important',
            borderBottom: 'none',
        },
    }));

    let assessmentRows: any[] = [];
    propertyList[page]?.forEach((element: any) => {
        assessmentRows.push({
            apn: element.APN,
            ownerName: element.OWNER_NAME,
            address: element.ADDRESS,
            city: element.CITY,
            state: element.STATE,
            formattedZip: formatZip4Field(element.ZIP, element.ZIP4),
            zip: element.ZIP,
            zip4: element.ZIP4,
            landUse: element.LAND_USE,
            assessmentLegal: element.FULL_ASSESSMENT_LEGAL,
            propertyId: element.SPECTR_PROPERTY_ID,
            fips: element.FIPS,
            unit: element.UNIT_NUMBER,
            unitType: element.UNIT_TYPE
        });
    });


    return (
        <>
            <Box className={styles.customBoxLayout}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <StyledTable stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column: any) => (
                                    <StyledTableHeaderCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </StyledTableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <StyledTableBody>
                            {
                                //rows
                                //.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                assessmentRows
                                    .map((row: any) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.apn} sx={{ border: '1px solid black' }}>
                                                {columns.map((column: any) => {
                                                    let value = row[column.id];
                                                    if (column.id === "address") {
                                                        value = <AddressWithUnit address={row.address} unitType={row.unitType} unitNumber={row.unit} />;
                                                    }
                                                    return (
                                                        <StyledTableCell data-testid={row.apn + "_SearchResultRow"} onClick={() => onRowClicked(row)} key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </StyledTableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                        </StyledTableBody>
                    </StyledTable>
                </TableContainer>
            </Box>
            <Box sx={{ paddingTop: '24px' }}>
                <Box>
                    <StewartTableFooterPaginator
                        totalRows={totalRowCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage} />
                </Box>
            </Box>
        </>
    );
}
