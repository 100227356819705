import React from "react";
import { PropertyHeader, SectionCard, SectionDataBox } from "../../../components/StyledComponents/ParcelDetailStyles"
import { Collapse, Grid, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { addThousandsSeparator } from "../../../utils/common/commonUtils";
import styles from '../parcelDetails.module.css';
import { SectionHeader } from "../../../components/StyledComponents/CommonControls";

function PhysicalCharacteristic(props: any) {
    const [physicalCharacteristicOpen, setPhysicalCharacteristic] = React.useState(false);
    let physicalDetails: any;
    if (props && props.physicalDetails) {
        physicalDetails = props.physicalDetails;
    }
    return (
        <div>
            {
                props &&
                props.physicalDetails &&
                physicalDetails &&
                <SectionCard className={styles.sectionPadding}>
                    <SectionHeader onClick={() => setPhysicalCharacteristic(!physicalCharacteristicOpen)} variant="h6">
                        {physicalCharacteristicOpen ? <KeyboardArrowUpIcon data-testid="PhysicalCharacteristicClose" />
                            : <KeyboardArrowDownIcon data-testid="PhysicalCharacteristicOpen" />} &nbsp; Physical Characteristics
                    </SectionHeader>
                    <ul className={styles.parcelList}>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Bedrooms
                            </Typography>
                            <Typography data-testid="Bedrooms" className={styles.headerAnswer}>
                                {physicalDetails.no_of_bedroom}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Bath Full/Partial
                            </Typography>
                            <Typography data-testid="Bathrooms" className={styles.headerAnswer}>
                                {physicalDetails.no_of_bath + "/" + physicalDetails.no_of_partial_bath}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Year Built
                            </Typography>
                            <Typography data-testid="YearBuilt" className={styles.headerAnswer}>
                                {physicalDetails.year_built}
                            </Typography>
                        </li>
                        <li className={styles.parcelListData}>
                            <Typography className={styles.headerQuestion}>
                                Living Area (SF)
                            </Typography>
                            <Typography data-testid="LivingArea" className={styles.headerAnswer}>
                                {addThousandsSeparator(physicalDetails.living_area)}
                            </Typography>
                        </li>
                    </ul>
                    <Collapse in={physicalCharacteristicOpen} timeout="auto" unmountOnExit>
                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Total Rooms
                                </Typography>
                                <Typography data-testid="TotalRooms" className={styles.headerAnswer}>
                                    {physicalDetails.total_no_rooms}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    No. of Units
                                </Typography>
                                <Typography data-testid="NoOfUnits" className={styles.headerAnswer}>
                                    {physicalDetails.no_of_units}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Stories/Floors
                                </Typography>
                                <Typography data-testid="Stories-Floors" className={styles.headerAnswer}>
                                    {physicalDetails.no_of_stories}{physicalDetails.no_of_stories && physicalDetails.floor ? "/" : ""}{physicalDetails.floor}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Price ($/SF)
                                </Typography>
                                <Typography data-testid="Price" className={styles.headerAnswer}>
                                    {physicalDetails.prices ? "$" : ""}{physicalDetails.prices ? Number(physicalDetails.prices).toLocaleString() : ""}{physicalDetails.prices ? "/SF" : ""}
                                </Typography>
                            </li>
                        </ul>

                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Garage/No. of Cars
                                </Typography>
                                <Typography data-testid="Garage-NoOfCars" className={styles.headerAnswer}>
                                    {physicalDetails.garage_no_of_cars}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    No. of Buildings
                                </Typography>
                                <Typography data-testid="NoOfBuildings" className={styles.headerAnswer}>
                                    {physicalDetails.no_of_buildings}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Fireplace
                                </Typography>
                                <Typography data-testid="FirePlace" className={styles.headerAnswer}>
                                    {physicalDetails.no_of_fireplace}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Lot Size (SF/AC)
                                </Typography>
                                <Typography data-testid="LotSize" className={styles.headerAnswer}>
                                    {physicalDetails.lot_size_sf_ac}
                                </Typography>
                            </li>
                        </ul>

                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Style
                                </Typography>
                                <Typography data-testid="Style" className={styles.headerAnswer}>
                                    {physicalDetails.building_style_code}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Exterior Walls
                                </Typography>
                                <Typography data-testid="ExteriorWalls" className={styles.headerAnswer}>
                                    {physicalDetails.exterior_wall_code}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Construction Type
                                </Typography>
                                <Typography data-testid="ConstructionType" className={styles.headerAnswer}>
                                    {physicalDetails.type_construction}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Roof Material/Type
                                </Typography>
                                <Typography data-testid="RoofMaterial" className={styles.headerAnswer}>
                                    {physicalDetails.roof_cover_code}{physicalDetails.roof_cover_code && physicalDetails.roof_type_code ? "/" : ""}{physicalDetails.roof_type_code}
                                </Typography>
                            </li>
                        </ul>

                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Foundation Type
                                </Typography>
                                <Typography data-testid="FoundationType" className={styles.headerAnswer}>
                                    {physicalDetails.foundation_code}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Basement Type/Area
                                </Typography>
                                <Typography data-testid="BasementType" className={styles.headerAnswer}>
                                    {physicalDetails.basement_type_area}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    A/C
                                </Typography>
                                <Typography data-testid="A/C" className={styles.headerAnswer}>
                                    {physicalDetails.ac}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Heat Type
                                </Typography>
                                <Typography data-testid="HeatType" className={styles.headerAnswer}>
                                    {physicalDetails.heating_code}
                                </Typography>
                            </li>
                        </ul>

                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Pool
                                </Typography>
                                <Typography data-testid="Pool" className={styles.headerAnswer}>
                                    {physicalDetails.pool_code}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    View
                                </Typography>
                                <Typography data-testid="View" className={styles.headerAnswer}>
                                    {physicalDetails.view}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Property Type
                                </Typography>
                                <Typography data-testid="PropertyType" className={styles.headerAnswer}>
                                    {physicalDetails.property_type}
                                </Typography>
                            </li>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Land Use
                                </Typography>
                                <Typography data-testid="LandUse" className={styles.headerAnswer}>
                                    {physicalDetails.land_use}
                                </Typography>
                            </li>
                        </ul>

                        <ul className={styles.parcelList}>
                            <li className={styles.parcelListData}>
                                <Typography className={styles.headerQuestion}>
                                    Zoning
                                </Typography>
                                <Typography data-testid="Zoning" className={styles.headerAnswer}>
                                    {physicalDetails.zoining}
                                </Typography>
                            </li>
                        </ul>
                    </Collapse >
                </SectionCard >
            }
        </div >
    )
}

export default PhysicalCharacteristic;