import { Typography, Box } from '@mui/material';

type Props = {
    headerText: string;
};

const TabHeader = ({ headerText }: Props) => {
    return (
        <Box>
            <Typography
                sx={{
                    color: 'primary.dark',
                    fontSize: '20px',
                    fontWeight: '700',
                }}>
                {headerText}
            </Typography>
        </Box>
    );
}

export default TabHeader