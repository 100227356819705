import { useEffect, useRef, useState } from 'react'
import { Typography, Box, Grid } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './policyAPNSearch.module.css';
import { ClearButton, GradientButton, GridItem, StyledAutocomplete, ThemedClearIcon } from '../../components/StyledComponents/CommonControls';
import { StyledTextField } from '../../components/StyledComponents/StyledTextField';
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { APNSearchForm } from '../../entities/Types';
import { stateListArray } from '../../utils/constants/StateListConstant';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
// import { CountyListData, getCountyByStateCode } from '../getCounty/getCountySlice';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getLengthOfAstrik } from '../../utils/common/commonUtils';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import { errorMessages } from './PolicyAPNsearch';
import { CountyListData, getCountyByStateCode } from '../apn-search/getCounty/getCountySlice';
import { resetPolicyAPNState } from './policyAPNSearchSlice';
import { POLICY_APN_TAB } from '../../utils/constants/tabConstants';
import { stateParameter } from '../../entities/ApiModel/CountyRequest';
// import { errorMessages } from '../PolicyAPNsearch';

interface Props {
    onSubmit: Function;
    onClear: Function,
    formFields: APNSearchForm,
    raiseError: Function
}

const PolicyAPNSearchForm = ({ onSubmit, onClear, formFields, raiseError }: Props) => {

    const [countyList, setCounty] = useState<any>([]);
    const [isDisabled, setDisabled] = useState(true);
    const [isAPNDisabled, setAPNDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const isWildcardSearch = useRef(false);
    let countyRef: any;
    let stateRef: any;
    const apnRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useAppDispatch();
    const countyListState = useAppSelector(CountyListData);
    const rerenderRef = useRef(0);
    const defaultValues: DefaultValues<APNSearchForm> = {
        stateField: { id: "", label: "" },
        countyField: { county: "", fips: "" },
        APNField: "",
    };

    useEffect(() => {
        if (!formFields || formFields.APNField === "" || !formFields.countyField?.fips) {
            setDisabled(true);
            setAPNDisabled(true);
        }
    }, []);

    const messageRequired = "Required"
    const APNSearchValidationSchema = yup.object().shape({
        stateField: yup.object().shape({
            id: yup.string().required(messageRequired),
            label: yup.string().required(messageRequired),
        }),
        countyField: yup.object().shape({
            fips: yup.string().required(messageRequired),
            county: yup.string().required(messageRequired),
        }),
        APNField: yup.string()
            .matches(/^[a-zA-Z0-9.()/,:_+\-*&#; ]*$/, errorMessages.InvalidAPNError)
            .test('asterisk-count', errorMessages.DoubleAstrikError, value => {
                const asteriskCount = (value?.match(/\*/g) || []).length;
                return asteriskCount <= 1;
            })
            .max(60, errorMessages.MaxCharactersError)
            .required(errorMessages.APNRequiredError),
    });

    const form = useForm<APNSearchForm>({
        defaultValues,
        resolver: yupResolver(APNSearchValidationSchema)
    });
    const { register, handleSubmit, setValue, resetField, reset, watch, clearErrors, getValues, setError, formState: { errors }, control } = form;

    async function handleSubmitClick(formData: APNSearchForm) {
        formData.APNField = formData.APNField.trim();
        if (!formData.APNField) {
            setError("APNField", { type: "required", message: errorMessages.APNRequiredError });
            return;
        }

        if (getLengthOfAstrik(formData.APNField) === 1) {
            isWildcardSearch.current = true;
            formData = { ...formData, APNField: formData.APNField.split('*')[0] + "*" }
        }
        else if (getLengthOfAstrik(formData.APNField) > 1) {
            setError("APNField", { type: "InvalidAPN", message: "Invalid APN" })
            isWildcardSearch.current = false;
        }
        else
            isWildcardSearch.current = false;

        formData = { ...formData, isWildCardSearch: isWildcardSearch.current }
        onSubmit(formData);
    }

    useEffect(() => {
        if (countyListState.status === "loading")
            setLoading(true);
        else
            setLoading(false)

        if (countyListState && countyListState.county.match_code === "1"
            && (selectedState || formFields.stateField.id) && !countyListState.county.isExecuting) {
            setCounty(countyListState.county.countyList[POLICY_APN_TAB]);
            setDisabled(false);
            rerenderRef.current += 1;
            // setStateValue(prev => getValues("stateField.id"));
            setStateValue(getValues("stateField.id"));
        }
    }, [countyListState]);

    useEffect(() => {
        if (stateValue) {
            countyRef.focus();
        }
    }, [isDisabled, rerenderRef.current]);

    useEffect(() => {
        if (formFields
            && formFields.APNField !== ""
            && formFields.countyField?.fips !== "" && formFields.stateField?.id !== "") {
            setValue("APNField", formFields.APNField);
            setValue("countyField", formFields.countyField);
            setValue("stateField", formFields.stateField);
            isWildcardSearch.current = formFields.isWildCardSearch ?? false;
            setAPNDisabled(false);
        }
    }, [formFields]);

    useEffect(() => {
        raiseError(errors);
        if (!form.getValues().stateField.id) {
            stateRef.focus();
        }
        if (!form.getValues().countyField.fips) {
            countyRef.focus();
        }
    }, [errors.stateField, errors.countyField, errors.APNField]);

    const getCountyList = (event: any, value: any) => {
        if (value && value.id) {
            setCounty([]);
            resetField("countyField")
            resetField("APNField")
            const state = value.id.trim().toUpperCase();
            let param: stateParameter = {
                stateCode: state,
                pageName: POLICY_APN_TAB
            }
            setSelectedState(state);
            dispatch(getCountyByStateCode(param))
        }
    }

    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
    };

    const handleCountyChange = () => {
        resetField('APNField');
        setAPNDisabled(false);
        if (apnRef.current) {
            setTimeout(() => {
                apnRef?.current?.focus();
            }, 0);
        }
    }

    const resetErrorOnFields = () => {
        clearErrors("APNField");
        clearErrors("countyField");
        clearErrors("stateField");
    }

    const clearSearchResults = () => {
        reset(defaultValues);
        isWildcardSearch.current = false;
        resetErrorOnFields();
        setDisabled(true);
        setAPNDisabled(true);
        dispatch(resetPolicyAPNState())
        onClear();
    }

    const apnFieldWatch = watch('APNField');
    const [apnFieldFocused, setapnFieldFocused] = useState<boolean>(false);

    const handleFocus = (e: any) => {
        switch (e?.target?.id) {
            case "APNField": {
                setapnFieldFocused(true);
                break;
            }
        }
    }
    const handleBlur = (e: any) => {
        switch (e?.target?.id) {
            case "APNField": {
                setapnFieldFocused(false);
                break;
            }
        }
    }

    return (
        <Box>
            {isLoading &&
                <LoadingProgressModel />
            }
            <form onSubmit={handleSubmit(handleSubmitClick)}
                noValidate>
                <Grid container>
                    <Grid item xs={2} md={1.3} lg={1.3} xl={0.9}>
                        <GridItem sx={{ paddingLeft: "0px" }}>
                            <Controller
                                name="stateField"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <StyledAutocomplete
                                        {...field}
                                        {...register("stateField")}
                                        options={stateListArray}
                                        disableClearable
                                        getOptionLabel={(item: any) => (item.label ? item.label : "")}
                                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                        noOptionsText={"No state"}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                inputRef={input => {
                                                    stateRef = input;
                                                }}
                                                error={!!fieldState.error}
                                                autoFocus={true}
                                                label="State"
                                                required
                                                variant="outlined"
                                                data-testid="StateField"
                                            />
                                        )}
                                        onChange={(_, data) => { field.onChange(data); getCountyList(_, data) }}
                                    />
                                )}
                                data-testid="StateAutocomplete"
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} md={3.5} lg={3.3} xl={2.5}>
                        <GridItem>
                            <Controller
                                name="countyField"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <StyledAutocomplete
                                        {...field}
                                        disabled={isDisabled}
                                        openOnFocus
                                        {...register("countyField")}
                                        options={countyList}
                                        disableClearable
                                        noOptionsText={"No such county"}
                                        getOptionLabel={(item: any) => (item.county ? item.county : "")}
                                        isOptionEqualToValue={(option: any, value: any) => option.fips === value.fips}
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                        onChange={(_, data) => { field.onChange(data); handleCountyChange() }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                inputRef={input => {
                                                    countyRef = input;
                                                }}
                                                error={!!fieldState.error}
                                                label="County"
                                                required
                                                variant="outlined"
                                                data-testid="CountyField"
                                            />
                                        )}
                                        data-testid="CountyAutocomplete"
                                    />
                                )}
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} md={3.1} lg={3.5} xl={3.5} sx={{ position: 'relative' }}>
                        <GridItem>
                            <StyledTextField
                                {...register('APNField')}
                                error={errors.APNField && errors.APNField.message !== "" ? true : false}
                                id="APNField"
                                inputRef={apnRef}
                                InputLabelProps={{
                                    shrink: !!apnFieldWatch || apnFieldFocused
                                }}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                disabled={isAPNDisabled}
                                data-testid="APNField"
                                fullWidth label="APN" required variant="outlined" />
                            <FormHelperText className={styles.helperTextAlign} id="outlined-weight-helper-text">{isWildcardSearch?.current && "Wildcard Search Performed"}</FormHelperText>
                        </GridItem>
                    </Grid>
                    <Grid item md={4.1} lg={3.5} xl={3.5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <GridItem sx={{ textAlign: 'unset' }}>
                            <GradientButton type="submit" size='large' className={styles.buttonPadding}
                                data-testid="SearchButton" variant="contained" startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Search</Typography>
                            </GradientButton>
                            <ClearButton type="reset" size="large" className={styles.buttonPadding} sx={{ marginLeft: '23px' }}
                                data-testid="ClearButton" onClick={clearSearchResults} variant="outlined" startIcon={<ThemedClearIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Clear</Typography>
                            </ClearButton>
                        </GridItem>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default PolicyAPNSearchForm