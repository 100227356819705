import React, { useEffect, useRef, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { pushIcon } from "./PushPin";
/// <reference path="types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />
/// <reference path="types/MicrosoftMaps/Modules/Search.d.ts"/>
/// <reference path="types/MicrosoftMaps/Modules/SpatialDataService.d.ts"/>
/// <reference path="types/MicrosoftMaps/Modules/SpatialMath.d.ts"/>
/// <reference path="MicrosoftMaps/Modules/Traffic.d.ts"/>
/// <reference path="MicrosoftMaps/Modules/WellKnownText.d.ts"/>
/// <reference path="../../../node_modules/bingmaps/types/MicrosoftMaps/ConfigurationDrivenMaps.d.ts"/>

type BingMapProps = {
  bingMapsKey?: string | null;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  minWidth?: number | string;
  width?: number | string;
  onMapReady?: any;
  lat: number,
  long: number
};

const BingMapsReact: React.FunctionComponent<BingMapProps> = ({
  bingMapsKey,
  minHeight,
  minWidth,
  lat,
  long
}: BingMapProps) => {
  // refs
  const mapContainer = useRef<HTMLDivElement>(null);
  const map: React.MutableRefObject<Microsoft.Maps.Map | null> = useRef<Microsoft.Maps.Map>(null);
  const makeMap = useCallback(() => {
    if (typeof Microsoft !== 'undefined') {
      if (!map.current) {
        let bounds = Microsoft.Maps.LocationRect.fromLocations(new Microsoft.Maps.Location(lat, long), new Microsoft.Maps.Location(lat, long));
        map.current = new Microsoft.Maps.Map(mapContainer.current!, {
          credentials: bingMapsKey as string,
          center: new Microsoft.Maps.Location(lat, long),
          disablePanning: false,
          disableScrollWheelZoom: false,
          disableZooming: false,
          enableClickableLogo: false,
          showZoomButtons: false,
          showLocateMeButton: false,
          showMapTypeSelector: false,
          showScalebar: false,
          maxZoom: 17,
          minZoom: 17,
          maxBounds: bounds,
          showLogo: false,
          disableStreetside: false,
          showTermsLink: false,
        });
        var center = map.current.getCenter();
        var pin = new Microsoft.Maps.Pushpin(center, {
          icon: pushIcon,
        });
        map.current.entities.push(pin);
      }
    }
    else {
      console.log("Error: BingMap is not working")
    }
  }, [bingMapsKey]);

  useEffect(() => {
    makeMap();
  }, [makeMap]);

  return (
    <>
      <Box height={'430px'} width={'61.5%'} position={'absolute'}
        ref={mapContainer}>
        <Typography sx={{ fontSize: "12px" }} > Loading Map.......</Typography>
      </Box>
      <Box height={'430px'} width={'61.5%'} position={'absolute'}>
        {/* Empty div created for blocking the scroll to work above the map */}
      </Box>
    </>
  );
};

export default BingMapsReact;