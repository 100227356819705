import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { RootState } from '../../app/store';
// import { fetchByAssessmentOwnerSearch } from './policyOwnerNameSearchAPI';
// import { LegalSearchForm } from '../../entities/Types';
import { RootState } from '../../app/store';
import { OwnerNameSearchRequest } from '../../entities/ApiModel/OwnerNameSearchRequest';
import { fetchByPolicyOwnerSearch } from './policyOwnerNameSearchAPI';

export interface PolicyOwnerSearchResponse {
    match_code: string,
    message: string,
    total_count: number,
    isExecuting: boolean,
    PolicyOwnerSearch: Record<number, any[]>;
}

export interface PolicyOwnerSearchState {
    property: PolicyOwnerSearchResponse;
    formFields: any;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}
export const resetPolicyOwnerSearchState = createAction('REVERT_POLICY_OWNER_SEARCH_STATE');

const initialState: PolicyOwnerSearchState = {
    property: {
        match_code: "",
        message: "",
        total_count: 0,
        isExecuting: false,
        PolicyOwnerSearch: {},
    },
    formFields: {},
    status: 'idle',
};

export const searchByPolicyOwnerSearch = createAsyncThunk(
    'OwnerSearch/getPropertyByPolicyOwnerSearch',
    async (request: OwnerNameSearchRequest) => {
        const response = await fetchByPolicyOwnerSearch(request);
        return response;
    }
);

export const setPolicyOwnerSearchToState = createAsyncThunk(
    'OwnerSearch/setPolicyOwnerSearchToState',
    async (formData: OwnerNameSearchRequest) => {
        return formData;
    }
);

export const getPolicyOwnerSearchSlice = createSlice({
    name: 'PolicyOwnerSearch',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByPolicyOwnerSearch.pending, (state) => {
                state.status = 'loading';
                state.property.isExecuting = true;
            })
            .addCase(searchByPolicyOwnerSearch.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload) {
                    const pageNo = state.formFields.pageNo || 1;
                    // state.property.LegalSearch = action.payload.policy_search;
                    state.property.match_code = action.payload.match_code;
                    state.property.message = action.payload.message;

                    state.property.total_count = action.payload.total_count;
                    state.property.PolicyOwnerSearch[pageNo] = action.payload.policy_search;
                    state.property.isExecuting = false;
                }
            })
            .addCase(searchByPolicyOwnerSearch.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setPolicyOwnerSearchToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.formFields = action.payload
            })
            .addCase(resetPolicyOwnerSearchState, () => initialState);
    },
});

export const PolicyOwnerSearchData = (state: RootState) => state.rootReducer.PolicyOwnerSearchList;
export default getPolicyOwnerSearchSlice.reducer;
