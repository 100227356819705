import { TabPanel } from '@mui/lab'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './policySearch.module.css';
import SearchForm from './searchForm/SearchForm';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import {
    EXCEPTION_MATCH_TEXT, MULTIPLE_MATCH_TEXT, NO_COVERAGE_TEXT, NO_MATCH_FOUND,
    Policy_Invalid_Error_Message, Policy_Max_Error_Message, Policy_Required_Error_Message,
    SINGLE_MATCH_TEXT,
    keyEntriesPerPage,
    keyPageNo
} from '../../utils/constants/constants';
import TabHeader from '../../components/ui/tabHeader/TabHeader';
import { ValidationText } from '../../components/StyledComponents/CommonControls';
import PolicySearchResult from './searchResult/PolicySearchResult';
import MatchCodes from '../../utils/enums/MatchCodes';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import { PolicySearchData, resetPolicySearchState, searchByPolicyNumber, setPolicyNumberToState } from './policySearchSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PolicySearchForm } from '../../entities/Types';
import { POLICY_SEARCH_TAB } from '../../utils/constants/tabConstants';
import { PolicySearchRequest } from '../../entities/ApiModel/PolicySearchRequest';
import { getSessionStorage, setSessionStorage } from '../../utils/common/commonUtils';

export const errorTypes = {
    MatchError: "InvalidEntry",
    MaxError: "MaxLettersReached",
    RequiredError: "Required",
}

const PolicySearch = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const dispatch = useAppDispatch();
    const policySearchState = useAppSelector(PolicySearchData);

    async function handleSubmit(formData: PolicySearchForm) {
        try {
            if (!formData || !formData.policyNumber)
                return;
            dispatch(resetPolicySearchState());

            setMatchCode("");
            setLoading(true);

            //Get Page No and Rows Per Page from Session Storage
            const defaultPageNo = 1;
            const defaultRowsPerPage = 10;
            setSessionStorage(keyPageNo + POLICY_SEARCH_TAB, defaultPageNo);
            let page = defaultPageNo;//parseInt(getSessionStorage(keyPageNo + POLICY_SEARCH_TAB) ?? defaultPageNo.toString());
            let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + POLICY_SEARCH_TAB) ?? defaultRowsPerPage.toString())
            let request: PolicySearchRequest = {
                policy_file_number: formData.policyNumber,
                offset_value: ((page - 1) * rowsPerPage).toString(),
                fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
            }
            formData.pageNo = page;
            dispatch(searchByPolicyNumber(request));
            dispatch(setPolicyNumberToState(formData));
        }
        catch (e) {

        }
    }

    const raiseError = (errorType: any) => {
        switch (errorType) {
            case errorTypes.MatchError:
                setErrorMessage(Policy_Invalid_Error_Message);
                break;
            case errorTypes.MaxError:
                setErrorMessage(Policy_Max_Error_Message);
                break;
            case errorTypes.RequiredError:
                setErrorMessage(Policy_Required_Error_Message);
                break;
            default:
                setErrorMessage("");
                break;
        }
    }

    useEffect(() => {
        if (matchCode && matchCode !== "") {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setMatchText(EXCEPTION_MATCH_TEXT);
                    break;
                }
                // case MatchCodes.INVALID_APN.toString(): {
                //     setMatchText(INVALID_APN_TEXT);
                //     break;
                // }
                default: {
                    setMatchText("");
                }
            }
        } else {
            setMatchText("");
        }
    }, [matchCode]);

    useEffect(() => {
        if (policySearchState
            && policySearchState.property
            && policySearchState.property.match_code !== "" && !policySearchState.property.isExecuting) {
            setLoading(false)
            setMatchCode(policySearchState.property.match_code);
        }
        // console.log(policySearchState.property.policySearch)

        if (policySearchState.status === "failed") {
            setLoading(false);
        }
    }, [policySearchState]);

    async function handleClearSearch() {
        // if (matchCode === MatchCodes.INVALID_APN.toString())
        setMatchCode("");
    }

    async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let policy_file_Number = policySearchState?.formFields?.policyNumber;
        let request: PolicySearchRequest = {
            policy_file_number: policy_file_Number,
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
            isPaginationOperation: true
        }
        setMatchCode("");
        setMatchText("");
        // let formData = ;
        let formData = { ...policySearchState?.formFields, "pageNo": page }
        dispatch(setPolicyNumberToState(formData));
        dispatch(searchByPolicyNumber(request));
    }

    const handleChangeRowsPerPage = () => {
        dispatch(resetPolicySearchState());
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <TabPanel value="policySearchTab" sx={{ padding: 0 }}>
                <Box className={styles.boxLayout}>
                    <>
                        <TabHeader headerText={"Policy Search"} />
                        <Box sx={{ marginTop: '6px', marginBottom: '15px' }}>
                            <ValidationText>Search by Policy Number or File Number</ValidationText>
                        </Box>
                        <SearchForm raiseError={raiseError} onSubmit={handleSubmit} onClear={handleClearSearch} formFields={policySearchState?.formFields} />

                    </>
                    {
                        errorMessage
                        &&
                        <ErrorMessage errorMessageText={errorMessage} />
                    }
                </Box>

                <MatchStatus matchCode={matchCode} matchText={matchText} />
                {
                    policySearchState
                    && policySearchState.property
                    && Object.keys(policySearchState.property.policySearch).length > 0
                    &&
                    <PolicySearchResult
                        totalRowCount={policySearchState?.property?.total_count ?? 0}
                        tabName={POLICY_SEARCH_TAB}
                        loggingEventName={POLICY_SEARCH_TAB}
                        fetchNextData={handleFetchNext}
                        propertyList={policySearchState.property.policySearch}
                        changeRowsPerPage={handleChangeRowsPerPage} />
                }
            </TabPanel>
        </>
    )
}

export default PolicySearch