import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
// import ReactHtmlParser from 'react-html-parser';
import styles from './exception.module.css';
import parse from 'html-react-parser';

interface Props {
    exceptionText: string,
    sequenceNumber: string,
    policyNumber: string
}
const PolicyExceptionText = ({ policyNumber, exceptionText, sequenceNumber }: Props) => {
    return (
        <>
            <ul>
                <li>
                    {/* <Box className={styles.ExceptionStyle}> */}
                    <Typography className={styles.ExceptionStyle} sx={{display:'inline-block', width:"3.5%", verticalAlign:"top"}} data-testid={policyNumber + "_ExceptionSequenceNumber"} lineHeight={"28px"}>{sequenceNumber}.</Typography>
                    {/* </Box> */}
                    {/* <Box className={styles.ExceptionStyle}> */}
                    <Typography className={styles.ExceptionStyle} sx={{display:'inline-block', width:"96%", verticalAlign:"top"}} data-testid={policyNumber + "_ExceptionText"} lineHeight={"28px"}>{parse(exceptionText)}</Typography>
                    {/* </Box> */}
                </li>
            </ul>
        </>
        // <Grid container>
        // <Grid item xs={0.4} sm={0.4} md={0.4} lg={0.3} xl={0.25}>

        // </Grid>
        // <Grid item xs={11.6} sm={11.6} md={11.6} lg={11.7} xl={11.7}>

        // </Grid>
        // </Grid>
    );
};

export default PolicyExceptionText;
