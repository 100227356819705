import { Box, Card, Typography, styled } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

export const PropertyLabel = styled(Typography)`
    font-size:16px;
    font-weight:600;
`;

export const PropertyValue = styled(Typography)`
    font-size:16px;
    font-weight:500;
`;

export const PropertyHeader = styled(Typography)`
    font-weight: 700;
    word-wrap: break-word;
`;

// export const SectionHeader = styled(Typography)`
//     display: flex;
//     align-items: center;
//     padding: 25px 0px;
//     font-weight: 700;
//     color:#004d82;
//     font-size:20px;
//     cursor: pointer;
// `;

export const ParcelDetailHeader = styled(Typography)`
    display: flex;
    align-items: center;
    padding: 15px 0px;
    font-weight: 700;
    font-size:22px;
`;

export const SectionCard = styled(Card)`
    box-shadow: none;
`;

export const SectionDataBox = styled(Box)`
    padding: 15px;
    box-shadow: none;
`;

export const DownloadImageButton = styled(LoadingButton)(({ theme }) => `
    border: 1px solid;
    font-weight: 600;
    color: ${theme.palette.primary.main};
    margin-left: 10px;
    letter-spacing: normal;
`);
