import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { RootState } from '../../app/store';
import { fetchByAssessmentOwnerSearch } from './assessmentOwnerSearchAPI';
// import { LegalSearchForm } from '../../entities/Types';
import { LegalSearchRequest } from '../../entities/ApiModel/LegalSearchRequest';
import { LegalSearchFormType } from '../../entities/Types/LegalSearchForm';
import { RootState } from '../../app/store';
import { AssesementSearchFormType } from '../../entities/Types/AssesementSearchFormType';
import { AssessmentSearchRequest } from '../../entities/ApiModel/AssessmentSearchRequest';
import { OwnerNameSearchRequest } from '../../entities/ApiModel/OwnerNameSearchRequest';

export interface AssessmentOwnerSearchResponse {
    match_code: string,
    message: string,
    total_count: number,
    isExecuting: boolean,
    AssessmentOwnerSearch: Record<number, any[]>;
}

export interface AssessmentOwnerSearchState {
    property: AssessmentOwnerSearchResponse;
    formFields: any;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}
export const resetAssessmentOwnerSearchState = createAction('REVERT_ASSESSMENT_OWNER_SEARCH_STATE');

const initialState: AssessmentOwnerSearchState = {
    property: {
        match_code: "",
        message: "",
        total_count: 0,
        isExecuting: false,
        AssessmentOwnerSearch: {},
    },
    formFields: {},
    status: 'idle',
};

export const searchByAssessmentOwnerSearch = createAsyncThunk(
    'OwnerSearch/getPropertyByAssessmentOwnerSearch',
    async (request: OwnerNameSearchRequest) => {
        const response = await fetchByAssessmentOwnerSearch(request);
        return response;
    }
);

export const setAssessmentOwnerSearchToState = createAsyncThunk(
    'OwnerSearch/setAssessmentOwnerSearchToState',
    async (formData: OwnerNameSearchRequest) => {
        return formData;
    }
);

export const getAssessmentOwnerSearchSlice = createSlice({
    name: 'AssessmentOwnerSearch',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByAssessmentOwnerSearch.pending, (state) => {
                state.status = 'loading';
                state.property.isExecuting = true;
            })
            .addCase(searchByAssessmentOwnerSearch.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload) {
                    const pageNo = state.formFields.pageNo || 1;
                    // state.property.LegalSearch = action.payload.policy_search;
                    state.property.match_code = action.payload.match_code;
                    state.property.message = action.payload.message;

                    state.property.total_count = action.payload.total_count;
                    state.property.AssessmentOwnerSearch[pageNo] = action.payload.properties;
                    state.property.isExecuting = false;
                }
            })
            .addCase(searchByAssessmentOwnerSearch.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setAssessmentOwnerSearchToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.formFields = action.payload
            })
            .addCase(resetAssessmentOwnerSearchState, () => initialState);
    },
});

export const AssessmentOwnerSearchData = (state: RootState) => state.rootReducer.AssessmentOwnerSearchList;
export default getAssessmentOwnerSearchSlice.reducer;
