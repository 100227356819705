import MatchCodes from "../enums/MatchCodes";
import { v4 as uuidv4 } from 'uuid';
import config from "../../appsettings.json";
import { ASSESSMENT_SEARCH_TAB, LEGAL_SEARCH_TAB, OWNER_SEARCH_TAB, POLICY_ADDRESS_TAB, POLICY_APN_TAB, POLICY_OWNER_SEARCH_TAB, POLICY_SEARCH_TAB } from "../constants/tabConstants";
import { AssessmentLegalSearch_Logging_Event, AssessmentOwnerSearch_Logging_Event, PolicyAddressSearch_Logging_Event, PolicyAPNSearch_Logging_Event, PolicyLegalSearch_Logging_Event, PolicyOwnerSearch_Logging_Event, PolicySearch_Loggin_Event } from "../constants/constants";

export const getLengthOfAstrik = (value: string) => {
    return value?.replace(/[^*]/g, "").length;
}

export const getLength_of_Base64String = (base64String: string) => {
    try {
        const byteCharacters = atob(base64String);
        return byteCharacters.length;
    }
    catch {
        return 0
    }
}

export function setCookie(name: string, value: string, days?: number): void {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // days in milliseconds
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
}

// Get a cookie
export function getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const cookiesArray = document.cookie.split(";");

    for (let cookie of cookiesArray) {
        cookie = cookie.trim();
        if (cookie.indexOf(nameEQ) === 0) {
            return decodeURIComponent(cookie.substring(nameEQ.length));
        }
    }
    return null;
}

export const SearchLogResult = (code: any) => {
    switch (code) {
        case MatchCodes.UNIQUE_MATCH.toString(): {
            return "Single Match";
        }
        case MatchCodes.MULTIPLE_MATCH.toString(): {
            return "Multiple Match";
        }
        case MatchCodes.NO_COVERAGE.toString(): {
            return "ZIP4 Look-up";
        }
        case MatchCodes.INVALID_ADDRESS.toString(): {
            return "Invalid Address - No Match Found";
        }
        case MatchCodes.NO_MATCH.toString(): {
            return "Valid Address - No Match Found";
        }
    }
}

export const LogAPISearchResult = (code: any) => {
    switch (code) {
        case MatchCodes.UNIQUE_MATCH.toString(): {
            return "Single Match";
        }
        case MatchCodes.MULTIPLE_MATCH.toString(): {
            return "Multiple Match";
        }
        case MatchCodes.NO_MATCH.toString(): {
            return "No Match Found";
        }
    }
}


export const parseJwt = (token: any) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
}

export const formatUnitNumberField = (unitType: string, unitNumber: string) => {
    let formattedUnitNumber = "";
    if (unitType && unitNumber) {
        formattedUnitNumber = unitType + " " + unitNumber;
    }
    else if (!unitType && unitNumber) {
        formattedUnitNumber = "# " + unitNumber;
    }
    else if (unitType && !unitNumber) {
        formattedUnitNumber = unitType;
    }

    return formattedUnitNumber
}

export const formatZip4Field = (zip: string, zip4: string) => {
    let formattedZip = "";
    if (zip && zip4) {
        formattedZip = zip + "-" + zip4;
    }
    else if (!zip && zip4) {
        formattedZip = zip4;
    }
    else if (zip && !zip4) {
        formattedZip = zip;
    }
    return formattedZip
}

export const formatMailingAddress = (address: any, city: any, state: any, zip: any, unitDetails: string) => {
    let mailingAddress = ""
    if (address)
        mailingAddress += address;
    if (unitDetails)
        mailingAddress += " " + unitDetails + ","
    if (city)
        mailingAddress += (mailingAddress && !unitDetails) ? ", " + city : " " + city;
    if (state)
        mailingAddress += (mailingAddress && city) ? ", " + state : " " + state;
    if (zip)
        mailingAddress += " " + zip;
    return mailingAddress;
}

export const convertToPercent = (value: string) => {
    let parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
        return "";
    } else {
        return ((parsedValue * 100).toFixed(0));
    }
}

export const addThousandsSeparator = (value: any) => {
    let parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
        return "";
    } else {
        return parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const FormatDateField = (date: string) => {
    var formattedDate = "";
    if (date) {
        formattedDate = date.replace(/-/g, '/')
    }
    return formattedDate;
}

export const GetUUId = () => {
    const guid: string = uuidv4();
    return guid;
}

export const getSessionStorage = (keyName: any) => {
    const storedData = sessionStorage.getItem(keyName);
    return storedData;
};

export const setSessionStorage = (keyName: any, value: number) => {
    sessionStorage.setItem(keyName, value?.toString());
};

export const clearSessionStorageByKeyStartingWith = (startingString: string) => {
    for (let i = 0; i < sessionStorage.length; i++) {
        const key: string | null = sessionStorage.key(i);
        if (key && key.startsWith(startingString)) {
            sessionStorage.removeItem(key);
        }
    }
}

export const removeKeySessionStorageItemsStartingWith = (prefix: string) => {
    for (let key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key) && key.startsWith(prefix)) {
            sessionStorage.removeItem(key);
        }
    }
}

export const getEnvironmentTitle = () => {
    const { env } = config.AzureSettings ?? "";
    switch (env) {
        case "dev":
            return "SPECTR DEV";
        case "qa":
            return "SPECTR QA";
        case "uat":
            return "SPECTR UAT";
        default:
            return "SPECTR";
    }
};

export const getLoggingEventName = (tabName: string) => {
    switch (tabName) {
        case LEGAL_SEARCH_TAB:
            return PolicyLegalSearch_Logging_Event.Legal_Search_Event
        case POLICY_SEARCH_TAB:
            return PolicySearch_Loggin_Event.Policy_Search_Event
        case ASSESSMENT_SEARCH_TAB:
            return AssessmentLegalSearch_Logging_Event.Assessment_Search_Event
        case POLICY_ADDRESS_TAB:
            return PolicyAddressSearch_Logging_Event.Search_Event
        case POLICY_APN_TAB:
            return PolicyAPNSearch_Logging_Event.Search_Event
        case OWNER_SEARCH_TAB:
            return AssessmentOwnerSearch_Logging_Event.Assessment_Search_Event
        case POLICY_OWNER_SEARCH_TAB:
            return PolicyOwnerSearch_Logging_Event.Policy_Search_Event
        default:
            return "";
    }
}

export const validateLotFromAndTo = (fromValue: string | undefined, toValue: string | undefined): boolean => {
    fromValue = (fromValue ?? "").trim();
    toValue = (toValue ?? "").trim();

    // If either value is empty or both values are equal, return true
    if (!fromValue || !toValue || fromValue === toValue) {
        return true;
    }

    const fromIsNumber = isNumber(fromValue);
    const toIsNumber = isNumber(toValue);

    // If both values are numbers, compare them numerically
    if (fromIsNumber && toIsNumber) {
        if (parseInt(fromValue, 10) < parseInt(toValue, 10)) {
            return true;
        }
    } else if (!fromIsNumber && !toIsNumber) {
        // Perform a case-insensitive lexicographical comparison
        // if ((fromValue.toLowerCase() < toValue.toLowerCase()) || (fromValue.toLowerCase() === toValue.toLowerCase())) {
        //     return true;
        // }

        const result = compareAlphaNumeric(fromValue.toLowerCase(), toValue.toLowerCase());
        if (result <= 0) {
            return true;
        }
    } else if (fromIsNumber && !toIsNumber) {
        // Decide how to handle the case where one value is a number and the other is not
        // Example: you might decide that numbers are always less than strings
        return false;
    } else if (!fromIsNumber && toIsNumber) {
        // Example: you might decide that strings are always greater than numbers
        return false;
    }

    // If none of the conditions are met, return false
    return false;
}

function compareAlphaNumeric(str1: string, str2: string): number {
    // Direct comparison for equality first to handle identical strings
    if (str1 === str2) {
        return 0; // str1 is equal to str2
    }

    // Regular expression to capture both numeric and alphabetic parts in either order
    const regex = /(\d+|\D+)/g;

    // Extract parts of both strings (will match numeric and alphabetic parts separately)
    const parts1 = str1.match(regex);
    const parts2 = str2.match(regex);

    // If no matches are found (which shouldn't happen), fall back to lexicographical comparison
    if (!parts1 || !parts2) {
        return str1.localeCompare(str2);
    }

    const len = Math.min(parts1.length, parts2.length);

    for (let i = 0; i < len; i++) {
        const part1 = parts1[i];
        const part2 = parts2[i];

        const isNum1 = /^\d+$/.test(part1);
        const isNum2 = /^\d+$/.test(part2);

        if (isNum1 && isNum2) {
            // Compare numeric parts as integers
            const diff = parseInt(part1, 10) - parseInt(part2, 10);
            if (diff !== 0) {
                return diff;
            }
        } else if (!isNum1 && !isNum2) {
            // Compare alphabetic parts lexicographically
            const diff = part1.localeCompare(part2);
            if (diff !== 0) {
                return diff;
            }
        } else {
            // If one part is numeric and the other is alphabetic, treat numbers as smaller
            return isNum1 ? -1 : 1;
        }
    }

    // If all parts are equal, compare the lengths of the two strings
    return parts1.length - parts2.length;
}

export const isNumber = (value: string) => {
    return /^-?\d+$/.test(value);
}

export function areAllPropertiesEmpty(obj: any, statePropertyKey: string, countyPropertyKey: string): boolean {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (key !== statePropertyKey && key !== countyPropertyKey) {
                if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
                    return false; // If any property (except state and fips) is not empty, return false
                }
            }
        }
    }
    return true; // If all properties (except state and fips) are empty, return true
}

export const specialCharacterFormatting = (input: string | undefined): string => {
    if (!input)
        return "";
    // Match everything up to the first special character and keep only that part
    let result = input.match(/^[a-zA-Z0-9]*/)?.[0] || '';
    return result;
}
