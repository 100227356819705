import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { policyAPNList, resetPolicyAPNState, searchByPolicyAPN, setPolicyAPNToState } from './policyAPNSearchSlice';
import { APNSearchForm } from '../../entities/Types';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import MatchCodes from '../../utils/enums/MatchCodes';
import {
    SINGLE_MATCH_TEXT,
    MULTIPLE_MATCH_TEXT,
    MULTIPLE_MATCH_RESULT_TEXT,
    NO_COVERAGE_TEXT, NO_COVERAGE_RESULT_TEXT,
    EXCEPTION_MATCH_TEXT,
    EXCEPTION_MATCH_RESULT_TEXT,
    DEFAULT_EMPTY_TEXT, INVALID_APN_TEXT, INVALID_APN_RESULT_TEXT, keyPageNo, APNSearchErrorMessages, NO_MATCH_FOUND,
    keyEntriesPerPage
} from '../../utils/constants/constants';
import { APN_TAB, POLICY_APN_TAB } from '../../utils/constants/tabConstants';
import { clearSessionStorageByKeyStartingWith, getLengthOfAstrik, getSessionStorage, setSessionStorage } from '../../utils/common/commonUtils';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import PolicyAPNSearchForm from './PolicyAPNSearchForm';
import PolicySearchResult from '../policySearch/searchResult/PolicySearchResult';
import { PolicyAPNSearchRequest } from '../../entities/ApiModel/PolicyAPNSearchRequest';
import styles from './policyAPNSearch.module.css';

export const errorMessages = {
    InvalidAPNError: "APN_Is_Invalid",
    DoubleAstrikError: "Double_Astrik_Error",
    MaxCharactersError: "Maximum_60_Characters",
    APNRequiredError: "APN_Is_Required"
}

function PolicyAPNsearch() {
    const apnListState = useAppSelector(policyAPNList);
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(false);
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const [resultText, setResultText] = useState<string>("");
    const [searchedAPN, setSearchedAPN] = useState<APNSearchForm>();
    const [errorMessage, setErrorMessage] = useState("");

    const [isAssesementAPNSearch, setAssesementAPNSearch] = React.useState(true);
    const [isPolicyAPNSearch, setPolicyAPNSearch] = React.useState(false);


    async function handleSubmit(formData: APNSearchForm) {
        try {
            dispatch(resetPolicyAPNState())
            setMatchCode("");
            setErrorMessage("");
            clearSessionStorageByKeyStartingWith(keyPageNo + POLICY_APN_TAB);
            if (getLengthOfAstrik(formData.APNField) > 1) {
                dispatch(resetPolicyAPNState());
                setMatchCode(MatchCodes.INVALID_APN.toString());
                return;
            }
            setLoading(true);
            setSearchedAPN(formData);

            const defaultPageNo = 1;
            const defaultRowsPerPage = 10;
            setSessionStorage(keyPageNo + POLICY_APN_TAB, defaultPageNo);
            let page = defaultPageNo;//parseInt(getSessionStorage(keyPageNo + POLICY_SEARCH_TAB) ?? defaultPageNo.toString());
            let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + POLICY_APN_TAB) ?? defaultRowsPerPage.toString())

            let apnRequest: PolicyAPNSearchRequest = {
                state: formData.stateField.id.trim(),
                fips: formData.countyField.fips.trim(),
                county: formData.countyField.county.trim(),
                apn: formData.APNField.trim(),
                offset_value: ((page - 1) * rowsPerPage).toString(),
                fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
            };
            formData.pageNo = page;
            dispatch(setPolicyAPNToState(JSON.parse(JSON.stringify(formData))))
            dispatch(searchByPolicyAPN(apnRequest))
        }
        catch (e) {
        }
    }

    async function handleClearSearch(formData: APNSearchForm) {
        // if (matchCode === MatchCodes.INVALID_APN.toString())
        setMatchCode("");
    }

    useEffect(() => {
        if (apnListState
            && apnListState.addresses
            && apnListState.addresses.match_code !== "" && !apnListState.addresses.isExecuting) {
            setMatchCode(apnListState.addresses.match_code);
            setLoading(false);
        } else {
            setMatchCode("");
        }

        if (apnListState.status === "failed") {
            setLoading(false);
        }
    }, [apnListState]);

    useEffect(() => {
        if (apnListState?.formFields
            && apnListState?.formFields?.APNField !== ""
            && apnListState?.formFields?.countyField?.fips !== ""
            && apnListState?.formFields?.stateField?.id !== "") {

        }
    }, [apnListState?.formFields]);

    useEffect(() => {
        if (matchCode && matchCode !== "") {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND);
                    setResultText(DEFAULT_EMPTY_TEXT);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    setResultText(MULTIPLE_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    setResultText(NO_COVERAGE_RESULT_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setMatchText(EXCEPTION_MATCH_TEXT);
                    setResultText(EXCEPTION_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.INVALID_APN.toString(): {
                    setMatchText(INVALID_APN_TEXT);
                    setResultText(INVALID_APN_RESULT_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                    setResultText("");
                }
            }
        } else {
            setMatchText("");
            setResultText("");
        }
    }, [matchCode]);

    const raiseError = (errors: any) => {
        if (errors.stateField?.id?.type === "required" &&
            errors.countyField?.fips?.type === "required" && errors.APNField?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.All_Fields_Required);
        }
        else if (errors.countyField?.fips?.type === "required" && errors.APNField?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.County_APN_Required);
        }
        else if (errors.countyField?.fips?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.County_Required);
        }
        else if (errors.APNField?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.APN_Required);
        }
        else if (errors.APNField?.message === errorMessages.InvalidAPNError) {
            setErrorMessage(APNSearchErrorMessages.Invalid_APN);
        }
        else if (errors.APNField?.message === errorMessages.DoubleAstrikError) {
            setErrorMessage(APNSearchErrorMessages.Double_Astrik_Error);
        }
        else if (errors.APNField?.type === "max") {
            setErrorMessage(APNSearchErrorMessages.Maximum_APN_Error_60_Character);
        }
        else {
            setErrorMessage("");
        }
    }

    async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let apnRequest: PolicyAPNSearchRequest = {
            state: apnListState.formFields.stateField.id.trim(),
            fips: apnListState.formFields.countyField.fips.trim(),
            county: apnListState.formFields.countyField.county.trim(),
            apn: apnListState.formFields.APNField.trim(),
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
        };

        setMatchCode("");
        setMatchText("");
        let formData = { ...apnListState?.formFields, "pageNo": page }
        dispatch(setPolicyAPNToState(JSON.parse(JSON.stringify(formData))));
        dispatch(searchByPolicyAPN(apnRequest));
    }

    const handleAssesementAPNSearchClick = () => {
        setPolicyAPNSearch(false)
        setAssesementAPNSearch(true)
    }

    const handlePolicyAPNSearchClick = () => {
        setAssesementAPNSearch(false)
        setPolicyAPNSearch(true)
    }

    const handleChangeRowsPerPage = () => {
        dispatch(resetPolicyAPNState());
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box>
                <>
                    <Box className={styles.boxLayout} sx={{ position: 'relative' }}>
                        <PolicyAPNSearchForm onSubmit={handleSubmit} onClear={handleClearSearch} raiseError={raiseError} formFields={apnListState?.formFields} />
                        {
                            errorMessage
                            &&
                            <ErrorMessage errorMessageText={errorMessage} />
                        }
                    </Box>

                    {
                        matchCode
                        &&
                        <MatchStatus matchCode={matchCode} matchText={matchText} />
                    }
                    {
                        apnListState
                        && apnListState.addresses
                        && Object.keys(apnListState.addresses.properties).length > 0
                        &&
                        <PolicySearchResult
                            totalRowCount={apnListState?.addresses?.total_count ?? 0}
                            tabName={APN_TAB}
                            subTabName={POLICY_APN_TAB}
                            loggingEventName={POLICY_APN_TAB}
                            fetchNextData={handleFetchNext}
                            propertyList={apnListState.addresses.properties}
                            changeRowsPerPage={handleChangeRowsPerPage} />
                    }
                    {/* {
                        apnListState
                        && apnListState.addresses
                        && apnListState.addresses?.properties?.length > 0
                        &&
                        <>
                            <Box sx={{ mt: '15px', width: '100%', overflow: 'hidden' }}>
                                <PropertyTable tabName={APN_TAB} tabHeader={APN_TAB_HEADER} totalRowCount={apnListState.addresses.total_count} propertyData={apnListState.addresses.properties} />
                            </Box>
                        </>
                    } */}
                </>
            </Box >
        </>
    )
}

export default PolicyAPNsearch;