import styled from "@emotion/styled";
import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from './circularProgress.module.css';
import { ProgressContainer } from "../../StyledComponents/CommonControls";

// const ProgressContainer = styled("div")({
//   alignItems: "center",
//   background: "rgba(255, 255, 255, 255)",
//   borderRadius: "16px",
//   boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "center",
//   padding: "16px",
//   position: "relative",
//   "& .MuiCircularProgress-root": {
//     color: "#015E88",
//     height: "60px !important",
//     width: "60px !important",
//   },
// });

const LabelContainer = styled("div")({
  alignItems: "center",
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  left: 0,
  position: "absolute",
  right: 0,
  top: 0,
  "& .MuiTypography-root": {
    fontSize: "16px",
  },
});

const LoadingProgress = (props: CircularProgressProps & { value?: number }) => {
  return (<>
    <Box className={styles.overlay}></Box>
    <Box className={styles.overlayBlocker}></Box>
    <Box className={styles.alignSection}>
      <ProgressContainer className={styles.alignCentred}>
        <CircularProgress variant="determinate" {...props} />
        <LabelContainer>
          <Typography component="div" color="textSecondary">{`${Math.round(
            props.value ?? 0,
          )}%`}</Typography>
        </LabelContainer>
      </ProgressContainer >
    </Box>
  </>
  );
}

export default function LoadingProgressModel() {
  const [progress, setProgress] = useState(0);

  const maxPercent = 99;
  const duration = 10000;
  const updateInterval = 100;
  let percent = 0;

  useEffect(() => {
    document.body.classList.add(styles.loaderOverflowHidden);
    let intervalId = setInterval(() => {
      percent += (updateInterval / duration) * 100;
      percent = Math.min(percent, maxPercent);
      setProgress(percent);
      if (percent >= maxPercent) {
        clearInterval(intervalId);
      }
    }, updateInterval);
    return () => {
      document.body.classList.remove(styles.loaderOverflowHidden);
      clearInterval(intervalId);
      setProgress(0);
    };
  }, []);

  return (
    <>
      <LoadingProgress value={progress} />
    </>
  );
}
