import logAPI from "../../common/Api/logAPI";
import { axiosSecuredInstance } from "../../configuration/axiosConfig";
import { OwnerNameSearchRequest } from "../../entities/ApiModel/OwnerNameSearchRequest";
import { LogAPISearchResult } from "../../utils/common/commonUtils";
import { PolicyOwnerSearch_Logging_Event } from "../../utils/constants/constants";

export const fetchByPolicyOwnerSearch = async (request: OwnerNameSearchRequest) => {

    let inputRequest = {
        state: request.state,
        county: request.county,
        owner_name: request.ownerName,
        offset_value: request.offset_value,
        fetchnext_value: request.fetchnext_value
    };
    let config = {
        method: 'post',
        url: 'api/v1/policy/owner/search',
        data: JSON.stringify(inputRequest)
    };
    const response: any = (await axiosSecuredInstance.request(config)).data.data;
    logAPI({
        Event: PolicyOwnerSearch_Logging_Event.Policy_Search_Event,
        UserInput: request,
        SearchResult: LogAPISearchResult(response?.match_code),
        State: request.state,
        County: request.county,
        Fips: request.fips,
        OwnerName: request.ownerName
    });
    return response;
};