import React, { useEffect } from 'react'
import { SectionCard, SectionDataBox } from '../../../components/StyledComponents/ParcelDetailStyles'
import { Box, Grid, Typography } from '@mui/material'
import styles from './EmptySection.module.css';
import { SectionHeader } from '../../../components/StyledComponents/CommonControls';

interface Props {
    isDirect?: boolean,
    isReference?: boolean
}

function EmptySection({ isDirect = false, isReference = false }: Props) {

    let starteType = "";
    let starteText = "";
    useEffect(() => {
        if (isDirect) {
            starteType = "Direct Starter";
            starteText = "No Direct Starter(s) Available";
        }
        if (isReference) {
            starteType = "Reference Starter";
            starteText = "No Reference Starter(s) Available";
        }
    }, [isDirect, isReference]);

    return (
        <>
            <SectionCard sx={{ marginTop: "15px" }} className={styles.borderSection}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SectionHeader sx={{ paddingBottom: "10px" }} variant="h6">
                        {isDirect ? "Direct Starter" : isReference ? "Reference Starter" : ""}
                    </SectionHeader>
                </Box>
                <Grid container>
                    <Grid item xs={12}><Typography>
                        {isDirect ? "No Direct Starter(s) Available" : isReference ? "No Reference Starter(s) Available" : ""}
                    </Typography> </Grid>
                </Grid>
            </SectionCard>
        </>
    )
}

export default EmptySection