import { useEffect, useState } from 'react'
import { parseJwt } from '../../utils/common/commonUtils';
import { useMsal } from '@azure/msal-react';
import RootComponent from '../../components/RootComponent';
import SplashScreen from '../../components/common/SplashScreen';

function Authenticated() {
    const [isValidUser, setValidUser] = useState(false);
    const { instance } = useMsal();
    const [splashScreen, setSplashScreenOpen] = useState<boolean>(false);

    useEffect(() => {
        try {
            instance.handleRedirectPromise().then((response) => {
                if (response?.account) {
                    const idToken = response.idToken;
                    let idTokenData = parseJwt(idToken);
                    if (idTokenData && idTokenData.groups && idTokenData.groups.length > 0) {
                        instance.setActiveAccount(response.account);
                        setValidUser(true);
                        setSplashScreenOpen(true);
                    } else {
                        setValidUser(false);
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.href = "/InvalidAccess"
                    }
                }
                else {
                    setValidUser(true);
                }
            }).catch(error => {
                setValidUser(false);
                console.error(error);
            });
        }
        catch (e) {
            setValidUser(true);
            console.log(e);
        }
    }, [instance])

    return (
        <>
            {
                isValidUser &&
                <>
                    <SplashScreen isOpen={splashScreen} />
                    <RootComponent />
                </>
            }
        </>
    )
}

export default Authenticated